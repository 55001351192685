$off-white: #f5f7f9;
$modal-white: rgb(245, 247, 249);
$grey: #79878f;
$loading-grey: #ebebeb;
$slate: #799eb2;
$light-grey: rgb(225, 228, 229);
$blue: #2167f5;
// $blue: #245cdd;
$teal: rgb(101, 172, 212);
$mint: #50c6dc;
$label-grey: #79878F;
$slate: rgb(67, 70, 83);
$black: darken(#343538, 10);
$dark-blue: #153A62;
$primary: #343538;
$white: #FFF;
$border-grey: #DCE6EB;
$green: darken(rgb(112,195,101), 5);
$red: #F26c57;
$orange: #ffae0a;
$yellow: #ffa500;
$purple: darken(#5c6ac4, 10);
$ecommerce-green: #50B83C;
$dark-teal: #203A43;
$slow: .25s;
$fast: .1s;

$mobile-sm: 400px;
$mobile: 800px;
$tablet: 800px;
$laptop: 1000px;
$desktop: 1440px;

$border-radius: 28px;

$sans: 'proxima-nova', Helvetica, sans-serif;
// $display: 'brevia';
$display: 'degular';
$serif: "garamond-premier-pro", serif;
$title: 'Inter', sans-serif;