@import "variables.scss";

@mixin far {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
}

@mixin fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
}


@mixin fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Brands";
}

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  } 
  &:after {
    clear: both;
  }
  & {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
  }
}

@mixin wrapper {
  @include clearfix;
  max-width: 1200px;
  margin: 0px auto;
  padding-left: 10px;
  padding-right: 10px;

  @media(min-width: $mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }

  // @media(min-width: $tablet) {

  // }

  // @media(min-width: $mobile) {
    // padding-left: 30px;
    // padding-right: 30px;
  // }

  // @media(min-width: $desktop) {
  //   padding-left: 40px;
  //   padding-right: 40px;
  // }
}

@mixin overflow-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes loading {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #fff, 1.8em -1.8em 0 0em rgba(#fff, 0.2), 2.5em 0em 0 0em rgba(#fff, 0.2), 1.75em 1.75em 0 0em rgba(#fff, 0.2), 0em 2.5em 0 0em rgba(#fff, 0.2), -1.8em 1.8em 0 0em rgba(#fff, 0.2), -2.6em 0em 0 0em rgba(#fff, 0.5), -1.8em -1.8em 0 0em rgba(#fff, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(#fff, 0.7), 1.8em -1.8em 0 0em #fff, 2.5em 0em 0 0em rgba(#fff, 0.2), 1.75em 1.75em 0 0em rgba(#fff, 0.2), 0em 2.5em 0 0em rgba(#fff, 0.2), -1.8em 1.8em 0 0em rgba(#fff, 0.2), -2.6em 0em 0 0em rgba(#fff, 0.2), -1.8em -1.8em 0 0em rgba(#fff, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(#fff, 0.5), 1.8em -1.8em 0 0em rgba(#fff, 0.7), 2.5em 0em 0 0em #fff, 1.75em 1.75em 0 0em rgba(#fff, 0.2), 0em 2.5em 0 0em rgba(#fff, 0.2), -1.8em 1.8em 0 0em rgba(#fff, 0.2), -2.6em 0em 0 0em rgba(#fff, 0.2), -1.8em -1.8em 0 0em rgba(#fff, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(#fff, 0.2), 1.8em -1.8em 0 0em rgba(#fff, 0.5), 2.5em 0em 0 0em rgba(#fff, 0.7), 1.75em 1.75em 0 0em rgba(#fff, 0.2), 0em 2.5em 0 0em rgba(#fff, 0.2), -1.8em 1.8em 0 0em rgba(#fff, 0.2), -2.6em 0em 0 0em rgba(#fff, 0.2), -1.8em -1.8em 0 0em rgba(#fff, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(#fff, 0.2), 1.8em -1.8em 0 0em rgba(#fff, 0.2), 2.5em 0em 0 0em rgba(#fff, 0.5), 1.75em 1.75em 0 0em rgba(#fff, 0.7), 0em 2.5em 0 0em #fff, -1.8em 1.8em 0 0em rgba(#fff, 0.2), -2.6em 0em 0 0em rgba(#fff, 0.2), -1.8em -1.8em 0 0em rgba(#fff, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(#fff, 0.2), 1.8em -1.8em 0 0em rgba(#fff, 0.2), 2.5em 0em 0 0em rgba(#fff, 0.2), 1.75em 1.75em 0 0em rgba(#fff, 0.5), 0em 2.5em 0 0em rgba(#fff, 0.7), -1.8em 1.8em 0 0em #fff, -2.6em 0em 0 0em rgba(#fff, 0.2), -1.8em -1.8em 0 0em rgba(#fff, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(#fff, 0.2), 1.8em -1.8em 0 0em rgba(#fff, 0.2), 2.5em 0em 0 0em rgba(#fff, 0.2), 1.75em 1.75em 0 0em rgba(#fff, 0.2), 0em 2.5em 0 0em rgba(#fff, 0.5), -1.8em 1.8em 0 0em rgba(#fff, 0.7), -2.6em 0em 0 0em #fff, -1.8em -1.8em 0 0em rgba(#fff, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(#fff, 0.2), 1.8em -1.8em 0 0em rgba(#fff, 0.2), 2.5em 0em 0 0em rgba(#fff, 0.2), 1.75em 1.75em 0 0em rgba(#fff, 0.2), 0em 2.5em 0 0em rgba(#fff, 0.2), -1.8em 1.8em 0 0em rgba(#fff, 0.5), -2.6em 0em 0 0em rgba(#fff, 0.7), -1.8em -1.8em 0 0em #fff;
  }
}

@keyframes loading-alt {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $grey, 1.8em -1.8em 0 0em rgba($grey, 0.2), 2.5em 0em 0 0em rgba($grey, 0.2), 1.75em 1.75em 0 0em rgba($grey, 0.2), 0em 2.5em 0 0em rgba($grey, 0.2), -1.8em 1.8em 0 0em rgba($grey, 0.2), -2.6em 0em 0 0em rgba($grey, 0.5), -1.8em -1.8em 0 0em rgba($grey, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($grey, 0.7), 1.8em -1.8em 0 0em $grey, 2.5em 0em 0 0em rgba($grey, 0.2), 1.75em 1.75em 0 0em rgba($grey, 0.2), 0em 2.5em 0 0em rgba($grey, 0.2), -1.8em 1.8em 0 0em rgba($grey, 0.2), -2.6em 0em 0 0em rgba($grey, 0.2), -1.8em -1.8em 0 0em rgba($grey, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($grey, 0.5), 1.8em -1.8em 0 0em rgba($grey, 0.7), 2.5em 0em 0 0em $grey, 1.75em 1.75em 0 0em rgba($grey, 0.2), 0em 2.5em 0 0em rgba($grey, 0.2), -1.8em 1.8em 0 0em rgba($grey, 0.2), -2.6em 0em 0 0em rgba($grey, 0.2), -1.8em -1.8em 0 0em rgba($grey, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($grey, 0.2), 1.8em -1.8em 0 0em rgba($grey, 0.5), 2.5em 0em 0 0em rgba($grey, 0.7), 1.75em 1.75em 0 0em rgba($grey, 0.2), 0em 2.5em 0 0em rgba($grey, 0.2), -1.8em 1.8em 0 0em rgba($grey, 0.2), -2.6em 0em 0 0em rgba($grey, 0.2), -1.8em -1.8em 0 0em rgba($grey, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($grey, 0.2), 1.8em -1.8em 0 0em rgba($grey, 0.2), 2.5em 0em 0 0em rgba($grey, 0.5), 1.75em 1.75em 0 0em rgba($grey, 0.7), 0em 2.5em 0 0em $grey, -1.8em 1.8em 0 0em rgba($grey, 0.2), -2.6em 0em 0 0em rgba($grey, 0.2), -1.8em -1.8em 0 0em rgba($grey, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($grey, 0.2), 1.8em -1.8em 0 0em rgba($grey, 0.2), 2.5em 0em 0 0em rgba($grey, 0.2), 1.75em 1.75em 0 0em rgba($grey, 0.5), 0em 2.5em 0 0em rgba($grey, 0.7), -1.8em 1.8em 0 0em $grey, -2.6em 0em 0 0em rgba($grey, 0.2), -1.8em -1.8em 0 0em rgba($grey, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($grey, 0.2), 1.8em -1.8em 0 0em rgba($grey, 0.2), 2.5em 0em 0 0em rgba($grey, 0.2), 1.75em 1.75em 0 0em rgba($grey, 0.2), 0em 2.5em 0 0em rgba($grey, 0.5), -1.8em 1.8em 0 0em rgba($grey, 0.7), -2.6em 0em 0 0em $grey, -1.8em -1.8em 0 0em rgba($grey, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($grey, 0.2), 1.8em -1.8em 0 0em rgba($grey, 0.2), 2.5em 0em 0 0em rgba($grey, 0.2), 1.75em 1.75em 0 0em rgba($grey, 0.2), 0em 2.5em 0 0em rgba($grey, 0.2), -1.8em 1.8em 0 0em rgba($grey, 0.5), -2.6em 0em 0 0em rgba($grey, 0.7), -1.8em -1.8em 0 0em $grey;
  }
}

@mixin loading {
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    display: block;
    font-size: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    text-indent: -9999em;
    animation: loading .75s infinite ease;
  }

  &:after {
    transition: opacity .5s;
  }
}

@mixin loading-alt {
  @include loading;

  &:after {
    animation: loading-alt .75s infinite ease;    
  }
}

@mixin subtitle {
  font-size: 32px;
  font-weight: 600;
  margin: 0px auto;
}

@mixin box-shadow {
  box-shadow: 0 1px 1px rgba(0,0,0,.15);
}

@mixin card {
  max-width: 480px;
  margin: 0px auto;
  padding: 25px;
  background: lighten($off-white, 2.5);
  border-radius: 10px;
  position: relative;
  @include box-shadow;
}

@keyframes shake {
  0% {
    transform: translate(6px, 0);
  }
  50% {
    transform: translate(-6px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@mixin blue-button {
  font-family: $sans;;
  outline: none;
  border: none;
  background: $blue;
  color: $white;
  border-radius: 5px;
  height: 50px;
  line-height: 46px;
  padding: 0 40px;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
  background: $blue;
  display: inline-block;
  border: 2px solid transparent;
  text-shadow: 0 2px 0 transparent;
  transform: scale(1) translateZ(0px);
  transition: background $slow, transform $slow, box-shadow $slow;
  width: 200px;
  padding: 0px;
  text-align: center;
  position: relative;
  overflow: hidden;

  &:hover {
    color: $white;
    background: darken($blue, 10);
    transform: scale(1.02) translateZ(0px);
    transition: background $fast, transform $fast, box-shadow $fast;
  }

  &:focus {
    color: $white;
    transform: scale(1.01) translateZ(0px);
    background: darken($blue, 10);
    transition: background $fast, transform $fast, box-shadow $fast;
  }

  &:disabled {
    color: $white;
    background: darken($white, 30);
    text-shadow: 0 2px 0 darken($white, 40);
    border-color: darken($white, 40);
    cursor: not-allowed;

    &:hover {
      transform: scale(1) translateZ(0px);
      background: darken($white, 30);
      text-shadow: 0 2px 0 darken($white, 40);
      border-color: darken($white, 40);
    }
  }
}

@mixin blue-button-small {
  @include blue-button;
  width: auto;
  min-width: 100px;
  padding: 0 40px;
  height: 44px;
  line-height: 40px;
}

@mixin red-button {
  @include blue-button;
  background: $red;
  text-shadow: 0 2px 0 darken($red, 10);
  border-color: darken($red, 10);

  &:hover, &:focus {
    background: darken($red, 10);
    border-color: darken($red, 10);
  }
}

@mixin red-button-small {
  @include red-button;
  width: auto;
  min-width: 100px;
  padding: 0 40px;
  height: 44px;
  line-height: 40px;
}

@mixin green-button {
  @include blue-button;
  background: $green;
  text-shadow: 0 2px 0 darken($green, 10);
  border-color: darken($green, 10);

  &:hover, &:focus {
    background: darken($green, 10);
    border-color: darken($green, 10);
  }
}

@mixin green-button-small {
  @include green-button;
  width: auto;
  min-width: 100px;
  padding: 0 40px;
  height: 44px;
  line-height: 40px;
}

@mixin grey-button {
  @include blue-button;
  background: $grey;
  text-shadow: 0 2px 0 darken($grey, 10);
  border-color: darken($grey, 10);

  &:hover, &:focus {
    background: darken($grey, 10);
    border-color: darken($grey, 10);
  }
}

@mixin hero-button {
  @include blue-button;
  border-color: transparent !important;
  // background: #14c49e;
  background: #23b370;
  text-shadow: 0 2px 0 transparent;
  // box-shadow: 0 2px 3px rgba(0,0,0,.10);
  margin-right: 20px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: .5px;

  &:hover, &:focus {
    background: lighten(#23b370, 3);
    // background: lighten(#14c49e, 3);
    // box-shadow: 0 2px 1px rgba(0,0,0,.15);
  }
}

@mixin hero-button-blue {
  @include hero-button;
  background: $blue;

  &:hover, &:focus {
    background: darken($blue, 10);
  }
}

@mixin hero-button-red {
  @include hero-button;
  background: #cc2b5e;

  &:hover, &:focus {
    background: darken(#cc2b5e, 10);
  }
}

@mixin hero-button-purple {
  @include hero-button;
  background: $purple;

  &:hover, &:focus {
    background: darken($purple, 10);
  }
}

@mixin hero-button-dark-blue {
  @include hero-button;
  background: $dark-blue;

  &:hover, &:focus {
    background: darken($dark-blue, 10);
  }
}

@mixin hero-button-slate {
  @include hero-button;
  background: $slate;

  &:hover, &:focus {
    background: darken($slate, 10);
  }
}

@mixin hero-button-black {
  @include hero-button;
  background: darken($slate, 15);

  &:hover, &:focus {
    background: darken($slate, 25);
  }
}

@mixin hero-button-alt {
  @include hero-button;
  background: darken($white, 3);
  color: $blue;
  // box-shadow: 0 2px 3px rgba(0,0,0,.10);
  margin-right: 0px;

  &:hover, &:focus {
    color: $blue;
    background: darken($white, 0);
    // box-shadow: 0 2px 1px rgba(0,0,0,.20);
  }
}


@mixin close-button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: $red;
  color: $white;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  border: 2px solid darken($red, 10);
  text-shadow: 0 2px 0 darken($red, 10);
  transform: scale(1) translateZ(0px);
  transition: background $slow, transform $slow, opacity $slow;
  cursor: pointer;

  &:hover {
    color: $white;
    background: darken($red, 10);
    transform: scale(1.02) translateZ(0px);
    transition: background $fast, transform $fast, opacity $fast;
  }

  &:focus {
    color: $white;
    transform: scale(1.01) translateZ(0px);
    background: darken($red, 10);
    transition: background $fast, transform $fast, opacity $fast;
  }

  &:after {
    @include fas;
    content: '\f00d';
  }
}

@mixin draggable {
  display: block;
  font-size: 19px;
  border-top: 2px solid rgba(0,0,0,.05);
  padding: 15px 25px;
  font-weight: 600;

  &:first-child, &.first {
    margin-top: 0px;
    border-top: none;
  }

  .handle:before {
    display: inline-block;
    cursor: move;
    cursor: grab;
    @include fas;
    content: '\f58e';
    margin-right: 20px;
  }

  &.dragging .handle:before {
    cursor: grabbing;
  }
}

@mixin label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.5px;
  color: lighten($black, 10);
  display: block;
  margin-bottom: 5px;
  @include clearfix;
  position: relative;
  transition: color $fast;


  > span {
    float: right;
    display: inline-block;
    letter-spacing: 1px;
    opacity: .75;
  }

  &:after {
    @include fas;
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;
    line-height: 12px;
    vertical-align: middle;
  }

  > a {
    text-transform: none;
    letter-spacing: 0px;
    float: right;
    color: $label-grey;
    opacity: .8;
    transition: opacity $slow;

    &:hover {
      transition: opacity $fast;
      opacity: 1;
    }
  }

  &.inline {
    float: left;
    display: inline-block;
    line-height: 21px;
    vertical-align: bottom;
  }
}

@mixin select {
  height: 50px;
  appearance: none;
  outline: none;
  padding: 0 20px;
  font-size: 14px;
  display: block;
  border-radius: 5px;
  background: transparent;
  border: none;
  border-radius: 0px;
  font-weight: 600;
  text-transform: uppercase;
  width: 120px;
  padding: 0px;
  color: $grey;
  border-radius: 5px;
  background: rgba(0,0,0,.05);
  padding: 0 15px;
  transition: color $slow, background $slow;
  cursor: pointer;

  &:hover {
    transition: color $fast, background $fast;
    background: rgba(0,0,0,.1);
    color: darken($grey, 10);
  }
}

@mixin input {
  appearance: none;
  outline: none;
  line-height: 48px;
  min-height: 50px;
  padding: 0 20px;
  border: 1px solid darken($off-white, 30);
  font-size: 18px;
  display: block;
  margin: 0 0 30px 0;
  border-radius: 5px;
  width: 100%;
  font-weight: 500;
  transition: border-color $fast;

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

@mixin headline-serif-highlights {
  color: rgba($white, .75);

  > span, > a {
    color: $white;
    display: inline-block;
    position: relative;

    @media(min-width: $mobile) {
      &:after {
        position: absolute;
        bottom: 8px;
        width: 100%;
        left: 0px;
        border-bottom: 1px dashed rgba($white, .5);
        content: '';
      }
    }
  }
}

@mixin serif-highlights {
  color: darken($off-white, 60);

  > span, > a {
    color: $black;
    display: inline-block;
    position: relative;

    @media(min-width: $mobile) {
      &:after {
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0px;
        border-bottom: 1px dashed rgba($black, .5);
        // content: '';
      }
    }
  }  
}

@mixin logo {
  display: inline-block;
  // text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  font-family: $display;
  // letter-spacing: 1.5px;
  // -webkit-font-smoothing: antialiased;

  a {
    color: #000;

    &:after {
      display: none;
    }

    &:before {
      @include far;
      content: '\f111';
      margin-right: 5px;
      color: $black;
      font-size: 26px;
      line-height: 22px;
      top: -2px;
      position: relative;
      vertical-align: middle;
      // transform: rotate(-6deg);
      // background: white;
      // transition: transform $slow cubic-bezier(0.68, -2, 0.27, 4);
    }

    &:hover:before {
      transform: rotate(0deg);
      transition: transform $slow cubic-bezier(0.68, -2, 0.27, 4);
    }
  }
}

@mixin list-idx {
  position: absolute;
  right: 100%;
  top: 50%;
  border-radius: 28px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  text-align: center;
  background: lighten($grey, 20);
  // border: 2px solid transparent;
  // text-shadow: 0 2px 0 transparent;
  color: $white;
  font-weight: 600;
  transform: translateY(-50%);
  margin-right: 20px;
}

@mixin pill {
  display: inline-block;
  padding: 3px 8px;
  background: $blue;
  color: $white;
  border-radius: 3px;
  text-shadow: 0 2px 0 darken($blue, 10);
  margin: 0 5px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  float: right;
  margin-right: 0px;

  &.grey {
    background: $grey;
    text-shadow: 0 2px 0 darken($grey, 10);
  }

  &.green {
    background: $green;
    text-shadow: 0 2px 0 darken($green, 10);
  }

  &.red {
    background: $red;
    text-shadow: 0 2px 0 darken($red, 10);
  }
}

@mixin inline-label {
  color: $grey;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 13px;
  line-height: 18px;
  vertical-align: baseline;
  margin-right: 5px;
}

@mixin submitButton {
  &.loading {
    button[type="submit"] {
      > div {
        transform: translateY(-100%);
      }
    }
  }

  button[type="submit"] {
    @include blue-button;

    > div {
      position: absolute;
      height: 50px;
      top: -2px;
      left: 0px;
      width: 100%;
      transform: translateY(0%);
      transition: transform .4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

      > span {
        height: 50px;
        display: block;
        position: relative;
        line-height: 46px;
        border: 2px solid transparent;

        &:last-child {
          @include loading;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      background: $grey;
      pointer-events: none;
    }
  }
}

@mixin tooltip {
  display: inline-block;
  position: relative;
  z-index: 4;
  // float: left;
  top: -1px;
  // left: 5px;
  margin-left: 7px;

  > span {
    @include fas;
    background: lighten($grey, 10);
    border-radius: 100px;
    width: 18px;
    height: 18px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0px;
    transform: translateZ(0px) scale(.75);
    transition: transform $slow, background $slow;
    cursor: pointer;

    &:before {
      font-size: 10px;
      vertical-align: middle;
      transform: translateZ(0px) scale(1);
      content: '\f128';
      color: $white;
    }
  }

  .content {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: -100000px;
    transform: translateY(-110%) translateX(-50%);
    padding-bottom: 10px;
    width: 300px;
    transition: opacity $slow .2s, transform $slow .2s, left 0s .5s;

    > div {
      text-align: center;
      padding: 15px 20px;
      background: $black;
      color: $white;
      border-radius: 4px;
      box-shadow: 0 2px 2px rgba(0,0,0,.15);
      text-transform: none;
      letter-spacing: 0px;
      font-size: 15px;
      line-height: 23px;
      font-weight: 400;

      strong {
        font-weight: 600;
        color: $white;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      box-shadow: 1px 1px 2px rgba(0,0,0,.15);
      background: $black;
      width: 10px;
      height: 10px;
    }
  }

  &:hover {   
    > span {
      transform: scale(1.05);
      background: $black;
      transition: transform $fast, background $fast;
    } 

    .content {
      left: 50%;
      opacity: 1;
      transform: translateY(-100%) translateX(-50%);
      transition: opacity $fast, transform $fast, left 0s 0s;
    }
  }
}

@mixin more-info {
  width: 50%;
  float: right;
  text-align: center;
  opacity: .7;
  transition: opacity $slow;

  &:hover {
    opacity: 1;
    transition: opacity $fast;
  }

  > div {
    color: $grey;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 3px;
  }

  a {
    color: $grey;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;

    &:before {
      @include fas;
      content: '\f2f6';
      margin-right: 7px;
    }

    &:hover {
      color: $blue;
    }
  }
}

@mixin pagination-loading {
  transition: opacity $fast 0s;
  margin-bottom: 0px;

  &.loading {
    opacity: .5;
    transition: opacity $slow .1s;
  }
}

@mixin post-thumbnail {
  margin-bottom: 50px;
  position: relative;
  transform: translateY(0px) translateZ(0px);

  @media(min-width: $mobile) {
    max-width: 33.3333%;
    width: 100%;
    float: left;
    padding: 0 10px;
    // transform: translateZ(0px) scale(1);
    transition: transform $slow;  
    margin-bottom: 20px;

    &:hover {
      // transform: scale(1.02) translateZ(0px);
      // transition: transform $fast;

      .content .copy span {
        color: $blue;
        transition: color $slow;
      }
    }
  }

  .content {
    border-radius: $border-radius;
    overflow: hidden;
    // background: darken(desaturate($teal, 10), 40);
    // border: 1px solid $grey;
    background: darken($off-white, 5);
    // box-shadow: 0 10px 20px -4px rgba(50,50,93,.25), 0 6px 12px -6px rgba(0,0,0,.3), 0 -4px 12px -3px rgba(0,0,0,.025);
    position: relative;

    &, .copy, .wave-divider svg path {
      transition: transform $fast, background $fast, box-shadow $fast, fill $fast;
    }

    .wave-divider {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      transform: translateY(-95%);

      svg path {
        fill: darken($off-white, 5);
        // transition: fill $slow;
      }
    }

    .copy {
      position: relative;
    }

    .img {
      position: relative;
      height: 0px;
      width: 102%;
      left: -1%;
      padding-top: 53%;
      display: block;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    .copy {
      padding: 22px 22px;
      color: $black;

      @media(min-width: $mobile) {
        height: 220px;
        position: relative;
      }

      h6 {
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 21px;
        line-height: 1.25;
        // letter-spacing: 1px;
        // text-transform: uppercase;
      }

      p {
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: 600;
        color: darken($off-white, 65);
        line-height: 1.3;
        // font-style: italic;
        // opacity: .9;
        min-height: 74px;
        // max-height: 50px;
        overflow: hidden;
      }

      span {
        color: $black;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: color $fast;

        @media(min-width: $mobile) {
          position: absolute;
          bottom: 22px;
          left: 22px;
        }
      }
    }
  }

  &:hover {
    &, .copy, .wave-divider svg path {
      transition: transform $fast, background $fast, box-shadow $fast, fill $fast;
    }

    .content {
      // box-shadow: 0 2px 10px -1px rgba($black, 0), 0 0px 0px 1px rgba($black, .15);
      box-shadow: 0 0px 0px 1px rgba($black, .15);
      background: $white !important;
      transform: translateY(-1%);

      .wave-divider {
        svg path {
          fill: darken($white, 0);
          // transition: fill $fast;
        }
      }
    }

    span {
      opacity: 1 !important;
      transition: opacity $fast;
    }
  }
}

@mixin hero-copy {
  h3, h4 {
    font-family: 'Inter';
  }

  h3 {
    font-size: 42px;
    @media(min-width: $mobile) {
      font-size: 72px;
    }
    font-weight: 800;
    color: $white;
  }

  h4 {
    font-size: 28px;
    font-family: $serif;
    @include headline-serif-highlights;
    color: $white;

    @media(min-width: $mobile) {
      font-size: 40px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.45;
    // font-family: $title;
    // font-family: $serif;
    // @include serif-highlights;

    @media(min-width: $mobile) {
      font-size: 21px;
      max-width: 680px;
      // opacity: .9;
    }

    // color: lighten($teal, 35);
    color: $white;
    margin-top: 30px;
    // max-width: 1000px;

    a {
      color: $white;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: $white;
      }
    }
  }
}