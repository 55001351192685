@import './reset.scss';
@import './variables.scss';
@import './mixins.scss';

body {
  font-family: $sans;
  background: $white;
  margin: 0px;
  color: $black;
  top: 0px;
  padding: 0px;
}

html, body, #root {
  height: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

#free-trial {
  #header {
    position: absolute !important;
    &.active .wrapper {
      background: none !important;
    }
    &.active .logo a {
      color: #000 !important;;
    }
    .wrapper {
      box-shadow: none !important;
      background: none !important;
      .center {
        display: none !important;
      }
      .right {
        display: none !important;
      }
    }
  }
}
#globe {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 1000px;
  width: 100%;
  transform: translateX(-50%) translateY(-5%);
  opacity: 0;
  transition: opacity $fast;

  &.active {
    opacity: .1;
  }
}

button {
  appearance: none;
  outline: none;
  border: none;
  background: none;
  transition: color $slow;

  &:hover {
    transition: opacity $fast;
  }
}

a {
  color: $black;
  transition: color $slow, background $slow;
  text-decoration: none;
  outline: none;

  &:hover {
    color: $blue;
    transition: color $fast, background $slow;
  }
}

img {
  max-width: 100%;
}

.wrapper {
  @include wrapper;
}

$header-height: 70px;
$footer-height: 405px;

.mobile-menu-toggle {
  position: absolute;
  right: 0px;
  top: 0px;
  height: $header-height;
  line-height: $header-height;
  z-index: 10;
  color: $white;
  cursor: pointer;
  font-size: 32px;
  vertical-align: middle;
  text-align: center;
  width: 70px;

  &:before {
    @include fas;
    content: '\f0c9';
    position: relative;
    top: 2px;
  }

  &.active:before {
    color: $black;
    content: '\f00d';
  }

  &.black {
    color: $black;
  }
}

#mobile-nav {
  display: none;
  @media(min-width: $mobile) {
    display: none !important;
  }

  &.active {
    display: block;
  }

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 7;
  background: $off-white;

  .wrapper {
    height: 100%;
    margin: 0px;
    padding: 0px;

    .logo {
      line-height: $header-height;
      vertical-align: middle;
      padding-left: 10px;
      @include logo;
      margin-bottom: 10px;
    }

    .nav {
      padding-top: 15px;
      padding-bottom: 15px;
      border-top: 1px solid darken($off-white, 10);

      a {
        color: $black;
        display: block;
        font-size: 19px;
        padding: 15px 0px 15px 30px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}

@keyframes logo-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#header {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: $header-height;
  background: transparent;
  // box-shadow: 0 0 4px rgba(0,0,0,.15);
  z-index: 5;

  .wrapper {
    padding-left: 10px;
  }

  @media(min-width: $mobile) {
    top: 10px;
    position: fixed;
    .wrapper {
      box-shadow: 0 0 0 1px rgba(255,255,255,.15);
    }
  }

  &.black {
    .wrapper .logo a {
      color: $black;
    }

    @media(min-width: $mobile) {
      .wrapper {
        background: $white;
      }
    }
  }

  &.active .wrapper {
    @media(min-width: $mobile) {
      background: rgba(255,255,255,1);
      // backdrop-filter: saturate(180%) blur(3px);
      box-shadow: 0 0 0 1px rgba(0,0,0,.15);
    }
  }

  &.active .wrapper,
  &.black .wrapper {
    @media(min-width: $mobile) {
      // background: rgba(#000, .70);
      // background: rgba(47, 58, 66, .75);
      transition: background 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
      // transition-property: background, backdrop-filter, -webkit-backdrop-filter;

      .logo a, .center .nav > a, .center .nav > span {
        color: $black;
      }

      &:hover {
        background: rgba(255, 255, 255, 1);
      }

      .nav > a:hover, .nav > span:hover {
        background: rgba(lighten($blue, 20), .25);
        color: darken($blue, 20);
      }

      .right > a {
        box-shadow: inset 0 0 0 1px rgba($black, .30);
      }
    }    
  }

  .wrapper {
    height: $header-height;
    position: relative;

    @media(min-width: $mobile) {
      border-radius: 12px;
      padding: 0 20px;
      width: 1200px;
      max-width: calc(100% - 20px);
      transition: background 0.15s cubic-bezier(0.28, 0.11, 0.32, 1);
      // transition-property: background, backdrop-filter, -webkit-backdrop-filter;
    }

    .nav > a:hover, .nav > span:hover {
      background: rgba(lighten($white, 0), .15);
    }

    > div {
      line-height: $header-height;
      vertical-align: middle;
    }

    .logo {
      @include logo;
      position: relative;

      @media(min-width: $mobile) {
        top: -2px;
      }

      a:before {
        line-height: 37px;
      }
      a {
        color: $white;
      }
      a:before {
        background: linear-gradient(-10deg, $orange, $red, $blue, $yellow, $green, $blue);
        background-size: 400% 400%;
        animation: logo-bg 15s ease infinite;
        color: transparent;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        background-clip: text;
        z-index: 1;
        position: relative;
      }
      a:after {
        // background: $white;
        // content: '';
        // position: absolute;
        // z-index: 0;
        // width: 20px;
        // height: 20px;
        // left: 5px;
        // top: 25px;
        // transform: rotate(-6deg);
        // // background: white;
        // transition: transform $slow cubic-bezier(0.68, -2, 0.27, 4);
      }
      a:hover:after {
        // transform: rotate(0deg);
      }
    }

    .right {
      @media(max-width: $mobile) {
        display: none;
      }
      float: right;

      @media(max-width: 1100px) {
        a.log-in {
          display: none !important;
        }
      }
      a.not-authenticated {
        @include hero-button-alt;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        display: inline-block;
        width: auto;
        min-width: 115px;
        padding: 0 20px;
        border: none;
        text-transform: none;
        vertical-align: middle;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:before {
          @include fas;
          // content: '\f2f6';
          margin-right: 8px;
        }

        &.sign-up {
          margin-right: 13px;
          background: darken($blue, 0);
          color: $white;
          box-shadow: none !important;


          &:before {
            // content: '\f234';
          }
          &:hover, &:focus {
            background: lighten($blue, 3);
          }
        }
      }
    }

    .center {
      @media(max-width: $mobile) {
        display: none;
      }
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translateX(-54%);
    }

    .nav {
      @include clearfix;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      display: inline-block;
      border: none;
      text-transform: none;
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $blue;
      // margin-right: 25px;
      white-space: nowrap;
      padding: 0 0px;
      border-radius: 5px;
      opacity: 1;
      transition: opacity $slow;

      @media(min-width: $mobile) and (max-width: 1000px) {
        // margin-left: -80px;

        a, > span {
          padding: 0 10px !important;
        }
      }

      @media(min-width: 1000px) and (max-width: 1200px) {
        // margin-left: -80px;

        a, > span {
          padding: 0 14px !important;
        }
      }

      &:hover {
        opacity: 1;
        transition: opacity $fast;

        > a, > span {
          opacity: .75;
          transform: scale(.965);
        }

        > a:hover, > span:hover {
          opacity: 1;
          transform: scale(1.02);
        }
      }

      > span {
        font-size: 21px !important;
      }

      > a, > span {
        cursor: pointer;
        padding: 0 18px;
        display: inline-block;
        vertical-align: top;
        font-weight: 600;
        font-size: 15px;
        color: $white;
        background: rgba(0,0,0,0);
        border-radius: 5px;
        transition: opacity $slow, transform $slow, background $slow;

        &:hover {
          transition: opacity $fast, transform $fast, background $fast;
        }
        // vertical-align: middle;
        // line-height: $header-height;
        // display: inline-block;
        // float: left;
        // color: $white;
        // font-weight: 600;
        // margin-right: 30px;
        // text-align: center;
        // font-size: 14px;
        // text-transform: uppercase;
        // letter-spacing: 1px;
        // position: relative;
        // top: 1px;
      }
    }
  }
}

#search-modal {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: -100%;
  left: -100%;
  transition: top 0s .2s, left 0s .2s;

  &.active {
    top: 0px;
    left: 0px;
    transition: top 0s 0s, left 0s 0s;

    .overlay {
      background: rgba(0,0,0,.25);
      // backdrop-filter: blur(3px);
    }

    .content .form,
    .content .results {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .results {
    background: $white;
    max-height: 420px;
    overflow: auto;
    margin-top: 10px;

    .result {
      position: relative;
      padding: 14px 0;
      border-bottom: 1px solid darken($off-white, 00);
      display: block;
      cursor: pointer;

      &:hover {
        .description i {
          opacity: 1 !important;
        }
        .title {
          text-decoration: underline;
        }
      }

      .image {
        max-width: none;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        line-height: 0;
        overflow: hidden;
        left: 20px;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          border-radius: 5px;
        }
      }

      .result-content {
        padding-left: 90px;
        padding-right: 44px;

        .title {
          font-weight: 600;
          font-size: 18px;
          color: $black;
        }

        .description {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.4;
          color: darken($off-white, 70);

          i {
            margin-left: 5px;
            position: absolute;
            top: 20px;
            right: 20px;
            opacity: 0;
            color: darken($off-white, 30);
            transition: opacity $fast;
          }
        }
      }
    }
  }

  &.show-results {
    .results {
      display: block;
    }
  }

  .overlay {
    transition: background .2s cubic-bezier(0.28, 0.11, 0.32, 1);
    // transition-property: background, backdrop-filter, -webkit-backdrop-filter;
    background: rgba(0,0,0,0);
    // backdrop-filter: blur(0px);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .content {
    position: absolute;
    top: 20%;
    left: 50%;
    max-width: 600px;
    width: 100%;
    transform: translateX(-50%);

    .form, .results {
      transition: all .2s cubic-bezier(0.28, 0.11, 0.32, 1);
      box-shadow: 0 8px 14px 1px rgba(0,0,0,.15);
      border-radius: 15px;
      transform: translateY(5px);
      opacity: 0;
    }

    .form {
      padding: 10px;
      background: $white;
      opacity: 0;
      vertical-align: top;

      input {
        border: none;
        appearance: none;
        box-shadow: none;
        border-radius: 0px;
        line-height: 40px;
        font-size: 21px;
        width: calc(100% - 40px);
        outline: none;
        padding: 0px;
        padding-left: 5px;
      }

      i {
        float: left;
        font-size: 20px;
        width: 40px;
        line-height: 40px;
        display: inline-block;
        text-align: center;
        vertical-align: top;
        color: darken($off-white, 30);
      }
    }

    button {
      @include hero-button;
      float: right;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      display: inline-block;
      width: auto;
      min-width: 115px;
      padding: 0 20px;
      border: none;
      text-transform: none;
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}

.page-container {
  height: 100%;
  position: relative;

  .page-content {
    // padding-top: $header-height;
    @media(min-width: $mobile) {
      position: relative;
      padding-bottom: $footer-height;
      min-height: 100%;
    }
  }

  &.lock {
    overflow: hidden;
  }
}

#footer {
  position: relative;
  @media(min-width: $mobile) {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: $footer-height;
  }
  background: darken($white, 0);
  padding: 50px 0;
  overflow: hidden;
  z-index: 3;
  box-shadow: 0 10px 30px -10px rgba(50,50,93,.25);

  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0px;
    height: calc(100% + 10px);
    left: 0px;
    width: calc(100%);
    overflow: hidden;
    border-top: 1px solid darken($off-white, 0);
  }

  > div {
    position: relative;
    z-index: 1;

    @media(max-width: $mobile) {
      text-align: center;
    }

    .logo {
      @include logo;
      margin-bottom: 40px;
      @media(min-width: $mobile) {
        margin-bottom: 0px;
        position: absolute;
        top: -3px;
        left: 30px;
      }
    }

    .copyright {
      @include wrapper;
      position: absolute;
      bottom: -40px;
      @media(min-width: $mobile) {
        bottom: -60px;
      }
      left: 0px;
      width: 100%;
      padding-top: 10px;
      font-size: 13px;
      color: darken($off-white, 40);
      background: $off-white;
      padding: 8px 10px;
      border-radius: 10px;
      text-align: center;
      font-weight: 600;
      max-width: 320px;
      left: 50%;
      transform: translateX(-50%);
    }

    .left {
      text-align: left;
      @media(min-width: $mobile) {
        float: left;
      }

      .certs {
        @media(max-width: $mobile) {
          display: none;
        }

        margin-top: 50px;
        margin-left: 0px;

        img {
          margin: 0 10px;
          width: 50px;
          height: auto;
          opacity: .5;
          vertical-align: top;
          padding-top: 2px;

          &:first-child {
            padding-top: 0px;
          }
        }
      }
    }

    .links {
      display: block;
      margin-left: 0px;
      text-align: center;
      @include clearfix;

      @media(min-width: $mobile) {
        margin-bottom: 0px;
        text-align: right;
        float: right;
        // width: 80%;
      }

      > div {
        display: inline-block;
        vertical-align: top;
        display: block;
        margin-bottom: 40px;
        width: 50%;
        float: left;
        text-align: left;

        @media(min-width: $mobile) {
          display: inline-block;
          width: 150px;
          text-align: left;
          margin-right: 50px;
          margin-bottom: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        h5 {
          // text-transform: uppercase;
          // color: darken($off-white, 50);
          // letter-spacing: 1px;
          color: $black;
          font-weight: 700;
          font-size: 17px;
          margin-bottom: 20px;
          font-family: $title;
        }
      }

      a {
        font-weight: 600;
        font-size: 17px;
        vertical-align: middle;
        color: darken($grey, 20);
        display: block;
        margin-bottom: 10px;
        margin-top: 20px;

        @media(min-width: $mobile) {
          margin: 0px;
          display: block;
          margin-bottom: 15px;
          // width: 115px;
          // margin: 0 20px;
        }

        &:hover {
          color: $black;
          text-decoration: underline;
        }

        &:first-child {
          margin-left: 0px;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

#hero {
  position: relative;
  background: #fff;
  height: 600px;

  @media(min-width: $mobile) {
    height: 760px;
  }

  .new-pill {
    margin-top: -30px;
    margin-bottom: 30px;
    background: rgba(0,0,0,.1);
    border-radius: 50px;
    padding: 0px 15px 0px 6px;
    margin-left: -6px;
    color: $white;
    line-height: 21px;
    display: inline-block;
    font-weight: 500;
    opacity: 1;
    vertical-align: middle;
    height: 31px;
    cursor: pointer;
    transition: opacity $slow;

    &:hover {
      opacity: $fast;
      opacity: 1;
    }

    &:after {
      @include fas;
      content: '\f054';
      margin-left: 8px;
      vertical-align: middle;
      line-height: 30px;
      font-size: 10px;
      position: relative;
      top: 0px;
      transform: translateX(0px);
      transition: transform $slow;
    }

    &:hover:after {
      transform: translateX(1px);
      transition: transform $fast;
    }

    > p {
      display: inline-block;
      margin: 0px;
      line-height: 30px;
      vertical-align: middle;
      position: relative;
      top: 0px;
      font-size: 15px;

      @media(min-width: $mobile) {
        font-size: 16px;
      }
    }

    > span {
      display: inline-block;
      margin-right: 8px;
      background: #14c49e;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      padding: 0px 5px 0 7px;
      border-radius: 50px;
      font-size: 13px;
      vertical-align: middle;
      line-height: 20px;
      position: relative;
      top: 0px;
      transition: color $slow;
    }

    &:hover span {
      color: $white;
      transition: color $fast;
    }
  }

  #stripes {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    // -webkit-transform: skewY(-8deg);
    // transform: skewY(-8deg);
    // -webkit-transform-origin: 0;
    // transform-origin: 0;
    // background: linear-gradient(150deg,darken($blue, 15) 15%, darken($teal, 5) 70%, lighten($teal, 10) 94%);
    // background: linear-gradient(#53f 15%,$blue 70%);
    background-color: $blue;
    background-image: linear-gradient(-315deg, darken($blue, 30) 0%, darken($blue, 20) 74%);
    contain: paint;

    .wave-divider {
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 3;

      svg path {
        fill: $white;
      }
    }

    > svg {
      // display: none;
      fill: lighten($teal, 10);
      position: absolute;
      width: 500px;
      // display: none;
      height: 500px;
      buffered-rendering: static;
      transform: traslateX(-50%) translateY(-50%) translateZ(0px);

      @media(max-width: $mobile) {
        display: none;
      }

      &:nth-child(1) {
        top: -400px;
        left: -320px;
        width: 1000px;
        height: 1000px;
        fill: rgba(darken($blue, 40), .15);
      }

      &:nth-child(2) {
        bottom: -150px;
        right: -300px;
        width: 700px;
        height: 700px;
        fill: rgba(darken($teal, 40), .15);
      }
    }

    &.product, &.dark-teal {
      background: linear-gradient(150deg, darken(#c9316f, 2) 15%, darken(#c9316f, 10) 70%);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken(#203A43, 20), .35);
        }

        &:nth-child(2) {
          fill: rgba(darken(#2C5364, 10), .35);
        }
      }
    }

    &.yellow {
      background: linear-gradient(#fde899,#fad133);
    }

    &.rainbow {
      background: linear-gradient(150deg, darken($teal, 35) 15%, darken($teal, 15) 70%);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken($teal, 40), .15);
        }

        &:nth-child(2) {
          fill: rgba(darken($teal, 40), .15);
        }
      }
    }

    &.teal {
      background-color: #aff6cf;
      background-image: linear-gradient(315deg, darken(#9f98e8, 30) 0%, darken(#9f98e8, 45) 74%);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken(#9f98e8, 20), .15);
        }

        &:nth-child(2) {
          fill: rgba(darken(#9f98e8, 10), .15);
        }
      }

      > span {
        &:nth-child(1) {
          background: darken($teal, 15);
        }
        &:nth-child(2) {
          background: darken($teal, 2);
        }
        &:nth-child(3) {
          background: darken($teal, 10);
        }
        &:nth-child(4) {
          background: darken($mint, 10);
        }
        &:nth-child(5) {
          background: darken($mint, 0);          
        }
      }
    }

    &.reviews {
      background-image: linear-gradient(150deg, darken(#c9316f, 2) 15%, darken(#c9316f, 10) 70%);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken(#ee9ca7, 20), .15);
        }

        &:nth-child(2) {
          fill: rgba(darken(#ee9ca7, 10), .15);
        }
      }
    }

    &.green {
      background-color: #cc2b5e;
      background-image: linear-gradient(315deg, darken(#6a0928, 0) 0%, darken(#753a88, 20) 74%);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken(#753a88, 30), .15);
        }

        &:nth-child(2) {
          fill: rgba(darken(#753a88, 30), .15);
        }
      }
    }

    &.purple {
      background: linear-gradient(150deg, darken($purple, 20) 15%, darken(lighten($purple, 0), 10) 70%);
      // background: darken($purple, 15);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken($purple, 25), .15);
        }

        &:nth-child(2) {
          fill: rgba(darken($purple, 25), .15);
        }
      } 

      > span {
        &:nth-child(1) {
          background: darken($purple, 15);
        }
        &:nth-child(2) {
          background: darken($purple, 2);
        }
        &:nth-child(3) {
          background: darken($purple, 10);
        }
        &:nth-child(4) {
          background: darken(lighten($purple, 20), 10);
        }
        &:nth-child(5) {
          background: darken(lighten($purple, 20), 0);          
        }
      }      
    }

    &.dark-blue {
      background: linear-gradient(150deg, darken($dark-blue, 15) 15%, darken(lighten($dark-blue, 0), 10) 70%);

      > span {
        &:nth-child(1) {
          background: darken($dark-blue, 15);
        }
        &:nth-child(2) {
          background: darken($dark-blue, 2);
        }
        &:nth-child(3) {
          background: darken($dark-blue, 10);
        }
        &:nth-child(4) {
          background: darken(darken($teal, 20), 10);
        }
        &:nth-child(5) {
          background: darken(darken($teal, 20), 0);          
        }
      }

      > svg {
        &:nth-child(1) {
          fill: rgba(darken($dark-blue, 20), .15);
        }

        &:nth-child(2) {
          fill: rgba(darken($dark-blue, 20), .15);
        }
      }   
    }

    &.slate {
      background: linear-gradient(150deg, darken($slate, 25) 15%, darken(lighten($slate, 0), 15) 70%);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken($slate, 10), .15);
        }

        &:nth-child(2) {
          fill: rgba(darken($slate, 10), .15);
        }
      }

      > span {
        &:nth-child(1) {
          background: darken($slate, 15);
        }
        &:nth-child(2) {
          background: darken($slate, 20);
        }
        &:nth-child(3) {
          background: darken($slate, 10);
        }
        &:nth-child(4) {
          background: darken(darken(#799eb2, 20), 10);
        }
        &:nth-child(5) {
          background: darken(darken(#799eb2, 20), 0);          
        }
      }      
    }

    &.orange {
      // background: linear-gradient(150deg, darken($orange, 15) 15%, darken(lighten($orange, 20), 5) 70%, lighten(lighten($orange, 20), 20) 94%);
      background-color: #f39f86;
      // background-image: linear-gradient(315deg, darken(#01813a, 5) 0%, darken(#01813a, 10) 74%);
      background-image: linear-gradient(315deg,#3cb572,#235137 74%);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken(#01813a, 15), .15);
        }

        &:nth-child(2) {
          fill: rgba(darken(#01813a, 15), .15);
        }
      }   

      > span {
        &:nth-child(1) {
          background: darken($orange, 15);
        }
        &:nth-child(2) {
          background: darken($orange, 2);
        }
        &:nth-child(3) {
          background: darken($orange, 10);
        }
        &:nth-child(4) {
          background: darken(lighten($orange, 20), 10);
        }
        &:nth-child(5) {
          background: darken(lighten($orange, 20), 0);          
        }
      }      
    }

    &.black {
      // background: linear-gradient(150deg, darken($orange, 15) 15%, darken(lighten($orange, 20), 5) 70%, lighten(lighten($orange, 20), 20) 94%);
      background-color: $black;
      background-image: linear-gradient(315deg, $black 0%, $black 74%);

      > svg {
        &:nth-child(1) {
          fill: rgba(darken($black, 20), .35);
        }

        &:nth-child(2) {
          fill: rgba(darken($black, 10), .35);
        }
      }   

      > span {
        &:nth-child(1) {
          background: darken($black, 15);
        }
        &:nth-child(2) {
          background: darken($black, 2);
        }
        &:nth-child(3) {
          background: darken($black, 10);
        }
        &:nth-child(4) {
          background: darken(lighten($black, 20), 10);
        }
        &:nth-child(5) {
          background: darken(lighten($black, 20), 0);          
        }
      }      
    }
  }

  .content {
    @include wrapper;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
    height: 400px;

    @media(min-width: $mobile) {
      height: 400px;
      > div {
        width: 680px;
      }
    }

    &.center {
      > div {
        border-radius: $border-radius;
        // background: lighten($blue, 0);
        max-width: 800px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        // background: $dark-blue;
        @media(min-width: $mobile) {
          margin-top: -50px;
          padding: 50px;
        }

        h3 {
          font-weight: 800;

          @media(min-width: $mobile) {
            font-size: 82px;
          }
        }

        h4 {
          font-weight: 400;
          font-family: $serif;
          font-size: 28px;
          @media(min-width: $mobile) {
            font-size: 48px;
          }
          // font-weight: 300;
        }

        p {
          margin-left: auto;
          margin-right: auto;
          // font-weight: 700;
          font-size: 26px;
          line-height: 1.25;
          font-family: $serif;
          font-weight: 400;
          @include headline-serif-highlights;

          @media(min-width: $mobile) {
            font-size: 32px;
            line-height: 1.35;
            max-width: 700px;
            font-family: $serif;
            font-weight: 400;
          }
          // font-family: $highlight;
        }

        .actions a {
          margin: 0px 10px !important;
        }
      }
    }
  }

  @include hero-copy;

  .actions {
    @media(max-width: $mobile) {
      a {
        margin-bottom: 10px;
      }
    }
    margin-top: 40px;

    a.start {
      @include hero-button;
    }

    a.alt {
      @include hero-button-alt;
    }
  }

  .img {
    @media(max-width: $mobile) {
      display: none !important; 
    }
    @include wrapper;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    z-index: 1;
    text-align: right;
    // height: 100%;
    margin-top: 50px;
    transform: translateX(-50%) translateY(-50%);

    &.flat img {
      box-shadow: none;
      max-width: calc(100% - 400px);
      width: 100%;
      margin-right: -200px;
      margin-top: -500px;
    }

    img {
      display: inline-block;
      max-width: calc(100% - 620px);
      min-width: 430px;
      width: 480px;
      // margin-right: 100px;
      // box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16);
      transform: rotate(-8deg);
      border-radius: $border-radius;
    }
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.homepage #stripes {
  .wave-divider svg path {
    fill: $white !important;
  }
}

#featured-brands {
  text-align: center;
  position: relative;
  margin-bottom: 50px;
  padding: 40px 0 50px;
  background: $white;

  @media(max-width: $mobile) {
    margin-bottom: 50px;
    padding: 70px 0px 0px;

    .brands {
      width: 100%;
      overflow: auto;
      white-space: nowrap;

      .brand {
        display: inline-block;
        width: 33.333%;
        padding-bottom: 20px;
      }
    }
  }

  .wave-divider {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translateY(-98%);

    svg path {
      fill: $white;
    }

    &.inverted {
      top: auto;
      bottom: 0px;
      transform: translateY(98%) matrix(1,0,0,-1.1,0,0) translateZ(0);
    }
  }

  h4 {
    margin-bottom: 30px;
    font-size: 26px;
    padding: 0 5px;
    line-height: 1.25;
    @media(min-width: $mobile) {
      font-size: 27px;
      line-height: 1;
    }
    font-weight: 300;
    color: darken($black, 15);
    font-family: $serif;
    color: darken($off-white, 60);

    > span {
      color: $black;
      display: inline-block;
      position: relative;

      @media(min-width: $mobile) {
        &:after {
          position: absolute;
          bottom: 4px;
          width: 100%;
          left: 0px;
          border-bottom: 1px dashed rgba($black, .5);
          content: '';
        }
      }
    }  
  }

  .brand {
    max-width: 300px;
    vertical-align: middle;
    display: inline-block;
    width: 16.66666%;
    text-align: center;
    // filter: grayscale(100%);
    transition: all $slow;

    &:hover {
      filter: grayscale(0%);
      transition: all $fast;
    }

    img {
      vertical-align: middle;
      display: inline-block;
      // max-height: 80px;
      max-width: 80%;
      margin: 0px auto;
    }
  }
}

$moveInitial: 0px;
$moveFinal: -100%;
$brandCount: 18;

.marquee {
  position: relative;
  overflow: hidden;

  .inner {
    width: 100%;
    display: block;
    position: relative;
    white-space: nowrap;

    &:before, &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 150px;
      background: red;
      z-index: 1;
      top: 0px;
      left: 0px;
      background: linear-gradient(90deg, $white, transparent);
    }

    &:after {
      right: 0px;
      left: auto;
      background: linear-gradient(-90deg, $white, transparent);
    }

    .container {
      display: inline-block;
      width: #{180 * $brandCount}px;
      transform: translate3d(0%, 0, 0);
      animation: marquee 70s linear infinite;
      animation-play-state: running;
      // opacity: .8;

      span {
        padding: 0 20px;
        display: inline-block;
      }

      img {
        width: 140px;
        height: auto;
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        // filter: grayscale(100%);
        // transition: all $slow;
      }
    }
  }

  &:hover .inner .container {
    // animation-play-state: paused;
  }

  &.reverse {
    .container {
      // opacity: .9;
      animation: marquee-reverse 55s linear infinite;
    }
  }

  &.off-center {
    .container {
      // opacity: 1;
      animation: marquee 50s linear infinite;
    }
  }

  // &.static {
  //   .container {
  //     animation: none !important;
  //   }
  // }
}


@keyframes marquee {
  0% {
    transform: translate3d($moveInitial, 0, 0);
  }

  100% {
    transform: translate3d($moveFinal, 0, 0);
  }
}

@keyframes marquee-off {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(calc(-100% - 90px), 0, 0);
  }
}

@keyframes marquee-reverse {
  0% {
    transform: translate3d($moveFinal, 0, 0);
  }

  100% {
    transform: translate3d($moveInitial, 0, 0);
  }
}

.off-white-to-white {
  background: linear-gradient(-180deg, $off-white, lighten($off-white, 2));
}

.off-white {
  background: $off-white;
}

.ai-background {
  background: $white;
  // background: linear-gradient(-180deg, lighten($off-white, 2), #cdd8e8);
  // background: $dark-blue;
}

#social-proof {
  padding-top: 30px;

  @media(min-width: $mobile) {
    padding: 50px 0;
    padding-top: 80px;
  }

  .top {
    @include clearfix;

    .left {
      @media(min-width: $mobile) {
        float: left;
        max-width: 700px;
        width: 60%;
      }

      h4 {
        font-size: 32px;
        font-family: $title;
        font-weight: 600;
        margin-bottom: 20px;

        @media(min-width: $mobile) {
          font-size: 38px;
          max-width: 700px;
          // text-indent: -3px;
          margin-bottom: 10px;
        }

        color: $black;
      }

      h6 {
        font-size: 26px;
        margin-bottom: 15px;
        font-family: $serif;
        @include serif-highlights;
        color: $black;

        @media(min-width: $mobile) {
          font-size: 28px;
          margin-bottom: 80px;
          max-width: 700px;
        }
        font-weight: 500;
      }
    }

    .right {
      margin-top: 30px;
      margin-bottom: 30px;

      @media(min-width: $mobile) {
        float: right;
        width: calc(100% - 700px);
        max-width: 350px;
        min-width: 350px;
        margin-top: 0px;
      }
      // margin-right: -10px;

      a {
        display: block;
        border-radius: 15px;
        padding: 15px 20px;
        background: lighten($purple, 50);
        color: darken($purple, 30);
        border: 1px solid lighten($purple, 40);
        transform: translateY(0%);
        transition: transform $fast, border-color $fast, background $fast;


        h4 {
          font-size: 21px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .learn-more {
          font-weight: 700;
          font-size: 18px;

          i {
            position: relative;
            top: 2px;
          }
        }

        &:hover {
          color: $black;
          background: $white;
          border-color: lighten($purple, 20);
          transform: translateY(-1%);

          .learn-more {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .case-study-snippets {
    @media(min-width: $mobile) {
      width: calc(100% + 140px);
    }

    > div {
      @media(min-width: $mobile) {
        float: left;
        width: 33.3333%;
        padding: 0 35px;
        margin-left: -35px;
        position: relative;
      }

      .focus {
        margin-bottom: 30px;

        i {
          color: darken($off-white, 10);
          font-size: 52px;
          margin-bottom: 20px;
          display: none;
        }

        h4 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        p {
          font-size: 19px;
          font-weight: 500;
          // color: darken($off-white, 60);

          > span {
            font-weight: 600;
            color: $black;
          }
        }
      }

      .study {
        margin-bottom: 30px;
        @media(min-width: $mobile) {
          margin-left: -15px;
          margin-bottom: 0px;
          min-height: 232px;
        }
        border-radius: 15px;
        border: 1px solid darken($off-white, 10);
        padding: 18px 28px;
        background: $white;
        display: block;
        transform: translateY(0%);
        transition: transform $fast, border-color $fast;

        &, &:hover {
          color: $black;
          text-decoration: none;
        }

        img {
          height: 30px;
          width: auto;
          display: inline-block;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        p {
          @media(min-width: $mobile) {
            min-height: 100px;
            margin-top: 10px;
          }
          font-weight: 600;
          font-size: 19px;
          margin-top: 0px;
          margin-bottom: 10px;
          color: $black;
        }

        .learn-more {
          font-weight: 700;
          font-size: 18px;

          i {
            position: relative;
            top: 2px;
          }
        }

        &:hover {
          border-color: darken($off-white, 30);
          transform: translateY(-1%);

          .learn-more {
            text-decoration: underline;
          }
        } 
      }
    }
  }

}

#key-features,
#backend-features {
  padding-top: 30px;
  padding-bottom: 80px;
  position: relative;

  &.tight {
    padding-bottom: 30px;

    h4 {
      text-align: center;
    }

    .feature-wrapper {
      display: block;
      @media(min-width: $mobile) {
        padding: 0 15px;
      }
    }

    .features {
      margin: 0px;
      margin-top: 40px;

      &.off-white {
        .feature {
          // box-shadow: 0px 1px 0px 1px rgba($black, .15);
        }
        .feature .bottom {
          // background: $black;

          h6, & {
            // color: $white;
          }

        }
      }

      .feature {
        @include clearfix;
        float: none;
        width: 100%;
        padding: 0px;
        border: 1px solid darken($off-white, 10);
        height: auto;
        overflow: hidden;
        position: relative;
        margin: 0px auto;
        margin-bottom: 20px;
        padding: 0px;
        max-width: 1200px;
        transform: translateZ(0px);

        &.active i {
          opacity: 1 !important;
        }

        &.reverse {
          .top {
            left: 0px;
          }
          .video-wrapper:before {
            left: 12px;
          }
          .bottom {
            @media(min-width: $mobile) {
              i {
                right: 20px;
                left: auto;
              }
              float: right;
              border: none;
              border-left: 1px solid darken($off-white, 10);
            }
          }
        }

        .top {
          height: auto;
          overflow: hidden;
          line-height: 0;
          background: $white;
          text-align: center;

          @media(min-width: $mobile) {
            width: 50%;
            position: absolute;
            left: 50%;
            top: 0px;
            height: 100%;
          }

          img {
            width: 100%;
            height: auto;
          }

          .video-wrapper {
            position: relative;
            // cursor: pointer;
            width: 100%;
            @include loading-alt;
            padding-top: 100%;

            @media(min-width: $mobile) {
              padding-top: 0px;
              width: 100%;
              height: 100%;
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translateY(-50%) translateX(-50%);
            }

            &:before {
              @include fas;
              content: '\f04c';
              font-size: 10px;
              position: absolute;
              top: 12px;
              right: 12px;
              width: 22px;
              height: 22px;
              line-height: 20px;
              border-radius: 4px;
              text-indent: 1px;
              border: 1px solid $white;
              background: $black;
              color: $white;
              cursor: pointer;
              z-index: 4;
              opacity: 1;
              display: none;
            }

            &.has-touched:before {
              opacity: 1;
            }

            &.active {
              &:before {
                transition: opacity $fast;
                opacity: 0;
                text-indent: 0px;
                content: '\f04c';
              }

              &:hover:before {
                opacity: 0;
              }
            }
          }

          video {
            width: 100%;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translateY(-50%) translateX(-50%);
            z-index: 3;
            // height: 100%;
          }
        }

        .bottom {
          padding: 30px 20px;
          border-top: 1px solid darken($off-white, 10);
          background: $white;
          position: relative;

          @media(min-width: $mobile) {
            float: left;
            padding: 100px 70px;
            width: calc(50% + .5px);
            border: none;
            border-right: 1px solid darken($off-white, 10);
          }

          h6:after {
            display: none !important;
          }

          h6 {
            font-size: 26px;
            font-family: $serif;
            font-weight: 400;
            @include serif-highlights;
            color: $black;

            > span.rainbow {
              background: linear-gradient(to right, $purple, $blue, darken($green, 5), darken($yellow, 10), darken($orange, 10), darken($red, 10));
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            @media(min-width: $mobile) {
              font-size: 38px;
            }
          }

          p {
            font-weight: 500;
            color: lighten($black, 15);

            strong {
              color: $black;
            }

            @media(min-width: $mobile) {
              font-size: 19px;
              padding-right: 10px;
            }
          }

          i {
            color: darken($off-white, 30);
            margin-right: 10px;
            font-size: 28px;
            opacity: .25;
            transition: opacity $fast, color $fast;
            top: 30px;
            right: 20px;

            @media(min-width: $mobile) {
              font-size: 32px;
              top: 25px;
              left: 20px;
              // margin-right: 15px;
              // margin-left: -15px;
            }

            line-height: 24px;
            position: absolute;
            vertical-align: middle;
          }
        }
      }
    }

    .content h6 {
      font-size: 26px;
      @media(min-width: $mobile) {
        font-size: 36px;
      }
      text-align: center;
      font-family: $serif;
      @include serif-highlights;
    }
  }

  h4 {
    font-size: 32px;
    font-family: $title;
    font-weight: 600;
    margin-bottom: 20px;

    @media(min-width: $mobile) {
      font-size: 48px;
      // text-indent: -3px;
      margin-bottom: 5px;
    }

    color: $black;

    &:after {
      @media(min-width: $mobile) {
        // content: '\f080';
      }
      margin-left: 40px;
      @include fas;
      color: $teal;
      font-size: 50px;
      line-height: 36px;
      vertical-align: middle;
    }
  }

  h6 {
    font-size: 26px;
    margin-bottom: 15px;
    font-family: $serif;
    @include serif-highlights;
    color: $black;

    @media(min-width: $mobile) {
      font-size: 36px;
      margin-bottom: 30px;
    }
    font-weight: 500;
  }

  .content h6 {
    // color: $black !important;
    // font-family: $title;
  }

  p {
    font-size: 17px;
    margin-bottom: 15px;
    font-weight: 600;

    @media(min-width: $mobile) {
      font-size: 21px;
      margin-bottom: 25px;
    }
    max-width: 550px;
    line-height: 1.5;
  }

  .features {
    @include clearfix;
    margin-top: 50px;

    @media(min-width: $mobile) {
      margin-top: 70px;
      margin-left: -20px;
      margin-right: -20px;
    }

    .feature {
      margin-bottom: 40px;
      background: $off-white;
      border-radius: $border-radius;
      padding: 40px 10px;

      @media(min-width: $mobile) {
        margin-bottom: 0px;
        float: left;
        width: calc(33.333333% - 10px);
        margin: 0 5px;
        padding: 40px 20px;
        height: 330px;
      }

      h6 {
        // color: $blue;
        @media(min-width: $mobile) {
          font-size: 26px;
        }
        font-weight: 400;
        white-space: nowrap;

        &:after {
          @include fas;
          content: '\f086';
          color: darken($off-white, 30);
          margin-left: 10px;
          font-size: 28px;
          opacity: .75;
          @media(min-width: $mobile) {
            margin-left: 15px;
            font-size: 32px;
          }
          line-height: 25px;
          vertical-align: middle;
        }

        &.capture:after {
          content: '\e2ca';
        }

        &.style:after {
          content: '\f1fc';
          position: relative;
          left: -2px;
        }
      }

      p {
        font-size: 16px;
        @media(min-width: $mobile) {
          font-size: 18px;
        }
      }
    }
  }

  &.purple {
    h4, h6 {
      // color: $purple !important;
    }

    .content h6 {
      // color: $black !important;
    }

    .feature h6 {
      // color: $purple !important;
    }

    h4:after, h6:after {
      // color: lighten($purple, 20) !important;
    }

    h4:after {
      display: none;
    }
  }

  &.dark-blue {
    // h4, h6 {
    //   color: $dark-blue !important;
    // }

    .features h6 {
      // color: $dark-blue !important;
    }

    .content h6 {
      // color: $black !important;
    }

    h4:after, h6:after {
      // color: lighten($dark-blue, 20) !important;
    }

    h4:after {
      display: none;
    }
  }

  &.slate {
    h4, h6 {
      // color: $slate !important;
    }

    .content h6 {
      // color: $black !important;
    }

    .feature h6 {
      // color: $slate !important;      
    }

    h4:after, h6:after {
      // color: #799eb2 !important;
    }

    h4:after {
      display: none;
    }
  }

  &.dark-teal {
    h4, h6 {
      color: #203A43 !important;
    }

    .content h6 {
      color: #2C5364 !important;
    }

    h4:after, h6:after {
      color: #2C5364 !important;
    }

    h4:after {
      display: none;
    }
  }
}

#backend-features {
  // padding-bottom: 80px !important;
  padding-top: 20px !important;

  h4 {
    text-align: center;
    // color: $white !important;
  }

  .content {
    text-align: center;

    h6, p {
      // text-align: center;
      // color: $white !important;
    }

    p {
      // display: inline-block;
    }
  }
}


#example-pill {
  position: relative;
  z-index: 2;

  .wrapper {
    text-align: center;
    margin-top: -75px;
    margin-bottom: 110px;

    @media(min-width: $mobile) {
      margin-top: -40px;
      margin-bottom: 110px;
      br {
        display: none;
      }
    }

   > div {
      margin: 0px auto;
      // background: $teal;

      a {
        display: inline-block;
        // border: 2px solid $off-white;
        background: $off-white;
        padding: 15px 30px 15px 30px;
        border-radius: 30px;
        display: inline-block;
        font-weight: 600;
        color: $black;
        font-size: 18px;     

        @media(max-width: $mobile) {
          line-height: 1.5;

          &:after {
            display: none !important;
          }
        }

        &, &:after {
          transition: color $fast, border-color $fast, transform $fast;
        }

        &:after {
          @include fas;
          content: '\f061';
          font-size: 18px;
          line-height: 18px;
          vertical-align: middle;
          position: relative;
          top: -2px;
          margin-left: 10px;
          color: lighten($blue, 10);
          transform: translateX(0px);
        }

        &:hover {
          color: $blue;
          border-color: $blue;

          &, &:after {
            transition: color $slow, border-color $slow, transform $slow;
          }

          &:after {
            color: $blue;
            transform: translateX(3px);
          }
        }
      }
    }
  }
}

.media #example-pill {
  a {
    &:after {
      color: lighten($dark-blue, 20) !important;
    }

    &:hover {
      &, &:after {
        color: $dark-blue !important;
        border-color: $dark-blue !important;
      }
    }
  }
}

.ecommerce #example-pill {
  a {
    &:after {
      color: lighten($purple, 20) !important;
    }
    
    &:hover {
      &, &:after {
        color: $purple !important;
        border-color: $purple !important;
      }
    }
  }
}

.software #example-pill {
  a {
    &:after {
      color: #799eb2 !important;
    }
    
    &:hover {
      &, &:after {
        color: $slate !important;
        border-color: $slate !important;
      }
    }
  }
}


.product #example-pill {
  a {
    &:after {
      color: #2C5364 !important;
    }
    
    &:hover {
      &, &:after {
        color: #203A43 !important;
        border-color: #203A43 !important;
      }
    }
  }
}

.grey-to-white {
  background: linear-gradient(-180deg, $off-white, $white);
  position: relative;
  z-index: 1;

  .wave-divider {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    transform: translateY(-80%);

    svg path {
      fill: $off-white;
    }
  }
}

.off-white-to-white {
  position: relative;

  .wave-divider {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    transform: translateY(-80%);

    svg path {
      fill: $off-white;
    }
  }
}

.white {
  background: $white;
  position: relative;

  .wave-divider {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    transform: translateY(-80%);

    svg path {
      fill: $white;
    }
  }
}

#bfcm .yellow {
  background: linear-gradient(#fde899,#fad133);

  .wave-divider {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    transform: translateY(-80%);

    svg path {
      fill: $white;
    }
  }
}


.off-white {
  position: relative;

  .wave-divider {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    transform: translateY(-80%);

    svg path {
      fill: $off-white;
    }
  }
}

#how-to-zigpoll,
#from-the-blog {
  margin-top: 30px;
  padding-top: 0px;
  z-index: 3;
  position: relative;
  // padding-bottom: 80px;
  // border-top: 2px solid $off-white;


  .title {
    text-align: center;
    margin-top: -62px;
    position: relative;
    z-index: 1;

    &:before {
      @include fas;
      content: '\f5dc';
      color: $teal;
      font-size: 60px;
      margin-bottom: 20px;
      background: $off-white;
      width: 120px;
      height: 120px;
      line-height: 120px;
      vertical-align: middle;
      text-align: center;
      border-radius: 500px;
      margin-bottom: 60px;
      border: 3px solid rgba($teal, .5);
      background: $white;
      opacity: 0;
    }

    h3 {
      color: $black;
      font-size: 28px;
      margin-bottom: 10px;
      font-family: $title;
      font-weight: 700;

      @media(min-width: $mobile) {
        font-size: 48px;        
        margin-bottom: 5px;
      }
    }

    p {
      // margin-top: 10px;
      color: darken($off-white, 60);
      font-family: $serif;
      @include serif-highlights;

      font-size: 26px;
      @media(min-width: $mobile) {
        font-size: 31px;
      }
    }
  }

  .below {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: $grey;
    margin-top: 5px;
    margin-bottom: 50px;
    @media(min-width: $mobile) {
      margin-top: 25px;
    }
    // opacity: .8;

    a {
      color: $blue;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        color: darken($blue, 10);
      }
    }
  }

  .list {
    margin-top: 40px;
    @media(min-width: $mobile) {
      margin-top: 60px;
    }
    @include clearfix;

    &:hover {
      .item .content > div .link {
        opacity: .5;
      }
    }

    .item {
      margin-bottom: 50px;
      position: relative;

      &:first-child {
        margin-bottom: 60px;
      }

      @media(min-width: $mobile) {
        margin-bottom: 0px !important;
        max-width: 33.3333%;
        float: left;
        padding: 0 10px;
        transform: translateZ(0px) scale(1);
        transition: transform $slow;  

        &:hover {
          // transform: scale(1.025) translateZ(0px);
          transform: translateY(-1%);
          transition: transform $fast, background $fast, box-shadow $fast;

          .wave-divider {
            svg path {
              fill: darken($purple, 10) !important;
              transition: fill $fast;
            }
          }

          .content > div {
            background: darken($purple, 10);
          }

          .content > div .link {
            opacity: 1;
            transition: opacity $fast;
          }
        }
      }

      .integration {
        position: absolute;
        width: 300px;
        transform: translateY(-50%) translateX(-50%);
        top: 0px;
        left: 50%;
        background: lighten($purple, 5);
        border-radius: 30px;
        color: $white;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        vertical-align: middle;
        box-shadow: 0 10px 20px -4px rgba(50,50,93,.25), 0 6px 12px -6px rgba(0,0,0,.3), 0 -4px 12px -3px rgba(0,0,0,.025);

        a {
          opacity: .9;
          color: $white;
          transition: opacity $slow;

          &:hover {
            opacity: 1;
            transition: opacity $fast;
          }
        }

        .img {
          max-width: 17px;
          display: inline-block;
          vertical-align: top;
          line-height: 30px;
          top: 5px;
          position: relative;
          margin-left: 5px;
        }
      }

      .content {
        border-radius: $border-radius;
        overflow: hidden;
        color: $white;
        // box-shadow: 0 3px 3px rgba(0,0,0,.1);
        // box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);

        img {
          display: block;
        }

        > div {
          padding: 30px 30px 40px;
          position: relative;
          margin-top: -105px;
          background: darken($purple, 0);
          transition: background $slow;

          .wave-divider {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            transform: translateY(-98%);

            svg path {
              fill: $purple;
              transition: fill $slow;
            }
          }

          &:before {
            // content: '';
            // background: url('/img/ecommerce.svg');
            background-size: 100% 100%;
            position: absolute;
            bottom: calc(100% - 1px);
            left: 0px;
            width: 100%;
            height: 100px;
          }

          h6 {
            font-weight: 600;
            margin-bottom: 10px;
            font-size: 21px;
            // letter-spacing: 1px;
            // text-transform: uppercase;
          }

          p {
            margin-bottom: 20px;
            font-size: 17px;
            font-weight: 300;
            line-height: 1.5;
            font-style: italic;
            opacity: .9;
          }

          .link {
            color: $white;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;
            opacity: 1;
            transition: opacity $slow;
          }
        }

        &.saas {
          > div {
            background: $slate;
          }
          > div:before {
            background: url('/img/saas.svg');
            transform: scaleX(-1);
          }
          svg path {
            fill: $slate !important;
          }

          &:hover {
            svg path {
              fill: darken($slate, 10) !important;
            }
            > div {
              background: darken($slate, 10);
            }
          }
        }

        &.media {
          > div {
            background: $dark-blue;
          }
          svg path {
            fill: $dark-blue !important;
          }
          > div:before {
            // background: url('/img/media.svg');
          }
          &:hover {
            svg path {
              fill: darken($dark-blue, 10) !important;
            }
            > div {
              background: darken($dark-blue, 10);
            }
          }
        }
      }
    }
  }
}

#how-to-zigpoll {
  margin-top: 50px;
  margin-bottom: 50px;  
}

#from-the-blog {
  // margin-top: 50px;
  // margin-bottom: 50px;
  padding-top: 100px;
  margin-top: 0px;

  .title:before {
    content: '\f5da';
    z-index: 1;
    position: relative;
  }

  .title:after {
    z-index: 0;
    width: 100%;
    height: 2px;
    // background: red;
    position: absolute;
    top: 0px;
    left: 0px;
    content: '';
  }

  .post-thumbnail {
    @include post-thumbnail;

    .content {
      box-shadow: 0 0px 0px 1px rgba($black, .15);
      background: $white !important;

      .wave-divider {
        svg path {
          fill: darken($white, 0);
          transition: fill $fast;
        }
      }
    }
  }

  .below {
    margin-top: -10px;

    a:after {
      @include fas;
      content: '\f178';
      margin-left: 5px;
      font-size: 21px;
      line-height: 16px;
      vertical-align: middle;
    }
  }

}

#primary, #secondary {
  overflow: hidden;
}

#get-started,
#get-started-alt,
#get-started-homepage,
#integrations {
  text-align: center;
  position: relative;
  // margin-top: -50px;
  // padding: 175px 0 100px;
  z-index: 2;
  background: #fff;

  .wave-divider {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translateY(-98%);

    svg path {
      fill: $white;
    }
  }

  // &:before {
  //   content: "";
  //   position: absolute;
  //   z-index: -1;
  //   width: 100%;
  //   height: 180%;
  //   top: 0;
  //   left: 0px;
  //   -webkit-transform: skewY(-8deg);
  //   transform: skewY(-8deg);
  //   background: $off-white;
  // }

  h3 {
    font-size: 32px;
    font-family: $title;
    font-weight: 700;

    @media(min-width: $mobile) {
      font-size: 52px;
    }
  }

  h6 {
    // margin-top: 10px;
    color: darken($off-white, 60);
    font-family: $serif;
    @include serif-highlights;
    margin-top: 8px;

    font-size: 21px;
    @media(min-width: $mobile) {
      font-size: 31px;
    }
  }

  .actions {
    margin-top: 40px;
    @media(min-width: $mobile) {
      height: 50px;
    }

    a {
      @include hero-button;
      margin: 0px;
    }

    > span {
      color: darken($off-white, 30);
      display: inline-block;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      vertical-align: top;
      text-align: center;
      width: 50px;

      @media(max-width: $mobile) {
        display: none;
      }
    }

    .shopify {
      @include hero-button-purple;
      margin: 0px;
      @media(max-width: $mobile) {
        margin-top: 10px;
      }
    }

    .wide {
      width: 240px;
    }
  }
}

#get-started-alt {
  // margin-top: -7%;
  // background: transparent;
  overflow: hidden;
  padding: 100px 0;
  width: 100%;

  // &:before {
  //   background: $white;
  // }
}

#get-started-alt.blue {
  background: linear-gradient(150deg,darken($blue, 15) 15%, darken($teal, 5) 70%, lighten($teal, 10) 94%);
}

#get-started-homepage {
  margin: 0px;
  padding: 0px;
  background: none;
  margin-bottom: -130px;

  @media(min-width: $mobile) {
    margin-top: 80px;
    margin-bottom: -80px
  }

  &:before {
    display: none;
  }  

  .wrapper {
    max-width: 900px;
    border: 1px solid darken($off-white, 10);
    background: lighten($white, 43);
    padding: 30px 10px;
    margin: 0 10px;
    border-radius: $border-radius;
    // box-shadow: 0 30px 60px -12px rgba(50,50,93,.15), 0 18px 36px -18px rgba(0,0,0,.2), 0 -12px 36px -8px rgba(0,0,0,.015);
    @include clearfix;

    @media(min-width: $mobile) {
      margin: 0px auto;
      padding: 60px 100px;
    }
  }
}

@mixin pricing-plans {
  .plans {
    @include clearfix;
    margin-top: 50px;
    // max-width: 1200px;

    @media(min-width: $mobile) {
      // margin: 0 -50px;
      min-width: 1100px;
      margin: 0px auto;
      margin-top: 50px;
    }

    @media(min-width: $mobile) {
      // &:hover {
      //   .plan {
      //     transform: scale(.98) !important;
      //     transition: transform $slow, opacity $slow;
      //     opacity: .85;
      //     z-index: 1;

      //     &:hover {
      //       transform: scale(1.06) !important;
      //       transition: transform $fast, opacity $fast;
      //       opacity: 1;
      //       z-index: 5;
      //     }
      //   }
      // }
    }

    .plan {
      margin-bottom: 10px;
      overflow: hidden;
      position: relative;

      @media(min-width: $mobile) {
        margin-bottom: 0px;
        width: calc(100% / 5);
        float: left;
        z-index: 2;
        padding: 0px 0px;
        margin-left: -1px;
        transition: transform $slow, opacity $slow;

        &:first-child .content {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
        &:last-child .content {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }

        &:nth-child(4) {
          // transform: scale(1.025);
          z-index: 3;

          .icon {
            height: 35px !important;
          }

          &:after {
            content: 'Most Popular';
            text-transform: uppercase;
            position: absolute;
            top: 13px;
            left: 0px;
            z-index: 2;
            width: 200px;
            height: 20px;
            vertical-align: middle;
            font-weight: 700;
            font-size: 11px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: $white;
            // background: $blue;
            border-radius: 7px;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            // border: 1px solid darken($blue, 0);
            // box-shadow: 0 0 10px -2px rgba(0,0,0,.25);
          }
        }

        &:nth-child(1) {
          transform: scale(1);
          z-index: 1;
        }

        &:nth-child(2) {
          transform: scale(1);
          z-index: 1;
        }

        &:nth-child(4) {
          transform: scale(1);
          z-index: 1;
        }
      }

      .content {
        background: $white;
        // border-radius: $border-radius;
        border: 1px solid #e0e0e0;
        padding: 0px;
        padding-top: 45px;
        padding-bottom: 45px;

        @media(max-width: $mobile) {
          border-radius: $border-radius;
        }
        @media(min-width: $mobile) {
          height: 640px;
          // min-height: 550px;
        }
        overflow: hidden;
        // box-shadow: 0 2px 10px -3px rgba(0,0,0,.25);

        .icon {
          // background: $slate;
          background: #c3c1c7;
          color: $white;
          text-align: center;
          height: 15px;
          line-height: 20px;
          width: 150px;
          border-radius: 50px;
          vertical-align: middle;
          top: 0px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%) translateY(-40%);
          // border-bottom: 3px solid darken($purple, 10);

          &:after {
            @include fas;
            content: '\f118';
            font-size: 70px;
            line-height: 100px;
            @media(min-width: $mobile) {
              font-size: 90px;
              line-height: 130px;
            }
            vertical-align: middle;
            text-shadow: 0 3px 10px darken($purple, 10);
            display: none;
          }

          &.basic:after {
            content: '\f580';
          }
          &.standard:after {
            content: '\f582';
          }
          &.plus:after {
            content: '\f587';
          }
          &.pro:after {
            content: '\f584';
          }
        }

        h4 {
          font-size: 28px;
          @media(min-width: $mobile) {
            font-size: 31px;
            // margin-bottom: 10px;
          }
          font-weight: 600;
          color: $black;
          text-align: center;
        }

        p {
          color: darken($grey, 10);
          font-size: 17px;
          @media(min-width: $mobile) {
            font-size: 18px;
          }
        }

        .price {
          text-align: center;
          font-size: 16px;
          color: darken($off-white, 25);

          @media(min-width: $mobile) {
            font-size: 20px;
          }

          > span {
            font-weight: 700;
            color: $slate;
            font-size: 45px;
            font-weight: 700;
          }
        }

        .response-modal {
          text-decoration: underline;
        }

        ul.top {
          margin-top: 8px;

          @media(min-width: $mobile) {
            min-height: 55px;
          }

          li {
            font-size: 18px;
            @media(min-width: $mobile) {
              font-size: 17px;
            }
            margin-bottom: 0px;
            white-space: nowrap;
            font-weight: 600;
            text-align: center;
            padding: 0 10px;

            a {
              color: $black;

              &:hover {
                text-decoration: underline;
              }
            }
            > span {
              font-weight: 400;
              color: darken($off-white, 25);
              font-size: 16px; 
            }
          }
        }

        ul.bottom {
          position: relative;

          &:before {
            content: 'Includes';
            color: $grey;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 1px;
            text-align: center;
            display: block;
            z-index: 2;
            position: relative;
            background: blue;
            width: 82px;
            margin: 0px auto;
            margin-bottom: 25px;
            background: $white;
          }

          &:after {
            z-index: 1;
            content: '';
            position: absolute;
            top: 7px;
            left: 0px;
            width: 100%;
            border-bottom: 1px solid darken($off-white, 10);
          }

          margin-top: 27px;
          padding: 0 20px;
          @media(min-width: $mobile) {
            padding: 0 15px;
          }

          li {
            display: block;
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 600;
            white-space: nowrap;
            // color: darken($grey, 20);
            color: $black;

            &:nth-child(1):before,
            &:nth-child(2):before,
            &:nth-child(3):before,
            &:nth-child(4):before {
              content: '\f00c';
            }

            &:before {
              @include fas;
              content: '\f067';
              display: inline-block;
              color: lighten($grey, 0);
              margin-right: 8px;
              font-size: 12px;
              vertical-align: middle;
              line-height: 17px;
              position: relative;
              top: -1px;
              width: 12px;
            }
          }
        }
      }

      &.pro {
        .content {
          // border-color: #886cf3;
        }
        .icon {
          background: #886cf3;
          // border-bottom: 3px solid darken(#886cf3, 10);

          &:after {
            text-shadow: 0 3px 10px darken(#886cf3, 10);
          }
        }

        .price > span {
          color: #886cf3;
        }

        ul.bottom:before,
        ul.bottom:after {
          color: #886cf3;
          // border-color: #886cf3;
        }
        ul.bottom > li:before {
          color: #886cf3;
        }
      }

      &.basic {
        .content {
          // border-color: #df901d;
        }

        .icon {
          background: #df901d;
          // border-bottom: 3px solid darken(#df901d, 10);

          &:after {
            text-shadow: 0 3px 10px darken(#df901d, 10);
          }
        }

        .price > span {
          color: #df901d;
        }

        ul.bottom:before,
        ul.bottom:after {
          color: #df901d;
          // border-color: #df901d;
        }

        ul.bottom > li:before {
          color: #df901d;
        }
      }

      &.standard {
        .content {
          // border-color: #2db67d;
        }

        .icon {
          background: #2db67d;
          // border-bottom: 3px solid darken(#2db67d, 10);

          &:after {
            text-shadow: 0 3px 10px darken(#2db67d, 10);
          }
        }

        .price > span {
          color: #2db67d;
        }

        ul.bottom:before,
        ul.bottom:after {
          color: #2db67d;
          // border-color: #2db67d;
        }

        ul.bottom > li:before {
          color: #2db67d;
        }
      }

      &.plus {
        .content {
          // border-color: $blue;          
        }
        .icon {
          background: $blue;
          // border-bottom: 3px solid darken($blue, 10);

          &:after {
            text-shadow: 0 3px 10px darken($blue, 10);
          }
        }

        .price > span {
          color: $blue;
        }

        ul.bottom:before,
        ul.bottom:after {
          color: $blue;
          // border-color: $blue;
        }

        ul.bottom > li:before {
          color: $blue;
        }
      }

      // &.pro {
      //   .icon {
      //     background: #df1d5a;
      //     // border-bottom: 3px solid darken(#df1d5a, 10);

      //     &:after {
      //       text-shadow: 0 3px 10px darken(#df1d5a, 10);
      //     }
      //   }

      //   .price > span {
      //     color: #df1d5a;
      //   }

      //   ul.bottom > li:before {
      //     color: #df1d5a;
      //   }
      // }

    }
  }

  h3 {
    color: $black;
    font-size: 28px;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 10px;
    font-family: $title;
    font-weight: 700;
    @media(min-width: $mobile) {
      font-size: 48px;        
      margin-bottom: 5px;
    }
  }

  h6 {
    // margin-top: 10px;
    color: darken($off-white, 60);
    font-family: $serif;
    @include serif-highlights;
    text-align: center;

    font-size: 26px;
    @media(min-width: $mobile) {
      font-size: 31px;
    }

  }

  .promo {
    display: block;
    text-align: center;

    > p {
      background: $blue;
      color: $white;
      display: inline-block;
      border-radius: 3px;
      padding: 8px 25px;
      margin-top: 25px;
      margin-bottom: 10px;
      font-weight: 600;
      position: relative;
      border-bottom: 2px solid darken($blue, 10);

      &:after {
        @include fas;
        content: '\f651';
        position: absolute;
        top: -25px;
        right: -27px;
        font-size: 40px;
        color: $green;
      }
    }

  }

  .below {
    color: darken($black, 0);
    font-size: 21px;
    padding: 0 10px;
    line-height: 1.25;
    margin-top: 10px;

    @media(min-width: $mobile) {
      font-size: 26px;
    }
    p {
      // opacity: .75;
      margin-bottom: 0px;
    }
    font-weight: 600;
    text-align: center;
  }

  // h6, .below p {
    // font-family: $serif;
  // }

  // h6, .below {
  //   font-size: 17px;
  //   @media(min-width: $mobile) {
  //     font-size: 28px;
  //   }
  //   margin-top: 10px;
  //   color: darken($off-white, 60);
  //   text-align: center;
  // }

  .below {
    @media(min-width: $mobile) {
      margin-top: 40px;
    }

    a {
      @include hero-button;
      display: block;
      vertical-align: middle;
      margin: 0px auto;
      margin-top: 14px;
    }
  }
}

#pricing-plans {
  // background: linear-gradient(180deg, #f6f9fc, $white);
  // background: $white;
  padding: 50px 0 80px;
  @media(min-width: $mobile) {
    // padding: 100px 0 80px;
  }
  position: relative;

  .blobs {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;

    svg {
      position: absolute;
      opacity: .05;

      &:nth-child(1) {
        top: -250px;
        right: 0px;
        max-width: 700px;
        height: auto;
        transform: translateY(-60%) translateX(30%) rotate(-29deg);
      }

      &:nth-child(2) {
        top: 250px;
        left: 0px;
        max-width: 900px;
        height: auto;
        transform: translateY(-50%) translateX(-40%) rotate(59deg);
      }

      &:nth-child(3) {
        bottom: 0px;
        right: 0px;
        max-width: 1200px;
        transform: translateY(50%) translateX(50%) rotate(19deg);
      }
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }

  @include pricing-plans;

  .below {
    > div {
      margin-top: 20px;

      @media(min-width: $mobile) {
        height: 50px;
      }

      a {
        @media(max-width: $mobile) {
          display: block;
          margin: 0px auto;
          margin-bottom: 5px;
        }

        @include hero-button;
        margin: 0px;
      }

      > span {
        color: darken($off-white, 30);
        display: inline-block;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        vertical-align: top;
        text-align: center;
        width: 50px;

        @media(max-width: $mobile) {
          display: none;
        }
      }

      .learn-more {
        @include hero-button-blue;
        margin: 0px;
        @media(max-width: $mobile) {
          margin-top: 10px;
        }
      }

      .wide {
        width: 240px;
      }

    }
  }
}

#create-account {
  text-align: center;
  position: relative;
  z-index: 2;
  background: linear-gradient(150deg, darken($dark-blue, 15) 15%, darken(lighten($dark-blue, 0), 10) 70%);
  padding-bottom: 2px;

  @media(min-width: $mobile) {
    padding-bottom: 80px;
  }

  > .wrapper {
    padding-top: 80px;
    padding-bottom: 80px;

    @media(min-width: $mobile) {
      padding: 80px 0;
    }
  }

  .wave-divider {
    svg path {
      fill: $white;
    }
  }

  h3 {
    font-size: 42px;
    font-family: $title;
    font-weight: 700;
    color: $white;

    @media(min-width: $mobile) {
      font-size: 52px;
    }
  }

  h6 {
    font-family: $serif;
    margin-top: 10px;
    @include serif-highlights;
    color: $white;
    font-size: 26px;
    font-weight: 400;

    @media(min-width: $mobile) {
      font-size: 31px;
    }
  }

  .actions {
    margin-top: 40px;

    @media(min-width: $mobile) {
      height: 50px;
    }

    @media(max-width: $mobile) {
      a {
        margin: 0px !important;
        margin-bottom: 10px !important;
      }
    }

    a.start {
      @include hero-button;
    }

    a.alt {
      @include hero-button-alt;
    }
  }

  #questions-feedback {
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 5px;
    background: $white;
    padding: 50px 30px;
    // box-shadow: 0 30px 60px -12px rgba(50,50,93,.15), 0 18px 36px -18px rgba(0,0,0,.2), 0 -12px 36px -8px rgba(0,0,0,.015);
    border-radius: $border-radius;
    @media(min-width: $mobile) {
      max-width: 670px;
      padding: 50px;
      margin: 0px auto;
      border: 1px solid $off-white;
      padding-bottom: 70px;
      border-radius: $border-radius;
    }

    h3 {
      font-size: 26px;
      @media(min-width: $mobile) {
        font-size: 42px;
      }
      font-weight: 600;
      color: $black;
    }

    h6 {
      font-size: 22px;
      @media(min-width: $mobile) {
        font-size: 28px;
      }
      // margin-top: 10px;
      margin-bottom: 0px;
      // color: $grey;
      // color: darken($off-white, 60);
      color: $black;
    }

    .actions {
      margin-top: 20px;

      @media(min-width: $mobile) {
        height: 30px;
      }

      @media(max-width: $mobile) {
        a {
          margin: 0px !important;
          margin-bottom: 10px !important;
        }
      }

      a.start {
        @include hero-button-blue;
      }
    }
  }

  &.purple {
    background: linear-gradient(150deg,darken($purple, 20) 15%, darken($purple, 15) 70%);

    #questions-feedback {
      a.start {
        @include hero-button-purple;
      }
    }
  }

  &.dark-blue {
    background: linear-gradient(150deg,darken($dark-blue, 15) 15%, darken($teal, 15) 70%, darken($teal, 5) 94%);

    #questions-feedback {
      a.start {
        @include hero-button-dark-blue;
      }
    }
  }

  &.slate {
    background: linear-gradient(150deg,darken($slate, 15) 15%, darken($slate, 10) 70%);

    #questions-feedback {
      a.start {
        @include hero-button-slate;
      }
    }
  }

  &.orange {
    background: linear-gradient(315deg, darken(#01813a, 5) 0%, darken(#01813a, 10) 74%);
    h6 {
      color: $white;
    }
  }

  &.red {
    background: linear-gradient(150deg,darken(#cc2b5e, 15) 15%, lighten(#753a88, 10) 94%);

    #questions-feedback .actions a {
      @include hero-button-red;
    }
  }

  &.dark-teal {
    background: linear-gradient(150deg, darken(#c9316f, 2) 15%, darken(#c9316f, 10) 70%);
  }

  &.yellow {
    background: linear-gradient(#fde899,#fad133);

    h3, h6 {
      color: $black;
    }
  }

  &.black {
    background: linear-gradient(150deg, darken($slate, 25) 15%, darken(lighten($slate, 0), 15) 70%);

    #questions-feedback {
      a.start {
        @include hero-button-black;
      }
    }
  }
}

#compare-list {
  margin: 50px 0;

  h4 {
    font-size: 21px;
    color: $grey;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 500;

    @media(min-width: $mobile) {
      font-size: 26px;
    }
  }

  .wrapper {
    max-width: 1000px;
  }

  .comparisons {
    @include clearfix;
    width: calc(100% + 20px;);
    margin-left: -10px;

    .comparison {
      @media(min-width: $mobile) {
        float: left;
        width: calc(50% - 20px);
      }
      margin: 0 10px 20px;
      border-radius: $border-radius;
      border: 1px solid darken($off-white, 10);
      background: lighten($off-white, 2);
      position: relative;
      text-align: left;
      padding: 30px;
      transition: border-color $slow, background $slow;

      h6 {
        color: $dark-blue;
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 600;
      }

      &.dark-teal h6,
      &.dark-teal a {
        color: #203A43;
      }

      p {
        text-align: left;
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 20px;
      }

      a {
        color: $dark-blue;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        opacity: 1;
        transition: opacity $slow;
      }

      &:hover {
        border-color: darken($off-white, 20);
        background: $white;
        transition: border-color $fast, background $fast;
      }
    }
  }
}

#integrations-block {
  margin: 50px 0;

  .wrapper {
    max-width: 1000px;
  }

  h4 {
    font-size: 21px;
    color: darken($grey, 10);
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 500;

    @media(min-width: $mobile) {
      font-size: 26px;
    }
  }

  .integrations {
    @include clearfix;
    width: calc(100% + 20px;);
    margin-left: -10px;

    .integration {
      float: left;
      width: calc(50% - 10px);
      margin: 0 5px 10px;

      @media(min-width: $mobile) {
        width: calc(33.333% - 20px);
        margin-bottom: 10px;
      }

      border-radius: $border-radius;
      // box-shadow: 0 10px 20px -4px rgba(50,50,93,.15), 0 6px 12px -6px rgba(0,0,0,.15), 0 -4px 12px -3px rgba(0,0,0,.015);
      border: 1px solid darken($off-white, 10);
      background: lighten($off-white, 2);
      position: relative;
      text-align: center;
      padding: 30px;
      transition: border-color $slow, background $slow;

      &:hover {
        border-color: darken($off-white, 20);
        background: $white;
        transition: border-color $fast, background $fast;
      }

      .coming-soon {
        position: absolute;
        top: 0px;
        left: 0px;
        color: $white;
        background: $purple;
        border-radius: 300px;
        padding: 5px 10px;
        font-weight: 600;
        margin-top: -15px;
        margin-left: -50px;
      }

      img {
        max-width: 200px;
        width: 100%;
        // max-height: 80px;
      }
    }
  }
}

#text-block {
  padding-top: 50px;
  padding-bottom: 80px;
  position: relative;

  .wrapper {
    margin: 80px auto 50px;
    max-width: 850px;
  }

  h4 {
    font-size: 32px;
    font-weight: 400;

    @media(min-width: $mobile) {
      font-size: 48px;
      // text-indent: -3px;
    }

    margin-bottom: 10px;
    color: $blue;
  }

  &.red h4 {
    color: #cc2b5e;
  }

  h6 {
    font-size: 21px;
    margin-bottom: 15px;
    color: $black;
    @media(min-width: $mobile) {
      font-size: 26px;
      margin-bottom: 30px;
    }
    font-weight: 500;
  }

  .content h6 {
    color: $black !important;
  }

  p {
    font-size: 17px;
    line-height: 1.75;
    max-width: 700px;
    margin-bottom: 20px;

    strong {
      font-weight: 600;
    }

    @media(min-width: $mobile) {
      font-size: 21px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

#hero.short {
  height: 300px;

  @media(min-width: $mobile) {
    height: 450px;
  }

  .content {
    height: 50px;

    @media(min-width: $mobile) {
      height: 150px;

      > div {
        width: 100%;
      }
    }
  }
}

#hero.black {
  h3, h4 {
    color: $black !important;
  }
}

#hero.center {
  .content {
    text-align: center;
  }

  @media(max-width: $mobile) {
    &.short {
      height: 350px;

      .content {
        height: 100px;
      }

      h4 {
        margin-top: 10px;
      }
    }
  }
}

#hero.tiny {
  height: 100px;

  @media(min-width: $mobile) {
    height: 200px;
  }
}


.terms {
  .terms-content {
    margin-bottom: 50px;
    margin-top: 50px;

    .article {
      margin-bottom: 20px;
      max-width: 800px;
    }

    .policy-title, h2 {
      color: $blue;
      font-size: 26px;
      margin-bottom: 10px;
      font-weight: 500;
    }

    h3 {
      color: $blue;
      font-size: 21px;
      margin-bottom: 30px;
      font-weight: 500; 
      margin-top: 50px;     
    }

    .policy-subheading {
      color: $blue;
      font-size: 24px;
      margin-bottom: 30px;
      font-weight: 500;
    }

    .spacer__2 {
      height: 20px;
      display: block;
    }

    .effective-day {
      color: $grey;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 20px;
    }

    p, li {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 1.5;
      color: darken($grey, 20);
    }

    ol {
      margin-left: 50px;
      counter-reset: list;

      li {
        list-style: none;
        margin-bottom: 10px;

        &:before {
          content: counter(list,lower-alpha) ") ";
          counter-increment: list;
        }

        p {
          display: inline;
        }
      }
    }
  }

}


#integrations {
  padding: 50px 0px 100px;
  margin-top: 10px;
  border-top: 2px solid $off-white;

  .img {
    margin: 0px auto;
    background: $off-white;
    width: 120px;
    height: 120px;
    border-radius: 120px;
    margin-top: -50px;
    overflow: hidden;
    padding: 25px;
    border: 2px solid $off-white;
    transform: translateY(-50%);
    margin-bottom: 0px;

    img {
      width: auto;
      height: auto;
      width: 100%;
      position: relative;
      top: -5px;
    }
  }

  h4 {
    color: $black;
    font-size: 28px;
    margin-bottom: 10px;
    text-align: center;
    font-family: $title;
    font-weight: 700;

    @media(min-width: $mobile) {
      font-size: 48px;
      margin-bottom: 20px;
    }
  }

  > div {
    text-align: center;
  }

  h6 {
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 40px;
    color: $black;
    font-family: $title;
    font-weight: 400;

    @media(min-width: $mobile) {
      font-size: 24px;
      margin-bottom: 60px;
    }
  }

  a {
    @include hero-button-purple;
    width: 270px;
  }

  &.wordpress {
    h4 {
      // color: $dark-blue;
    }

    a {
      background: $dark-blue;
    }

    .img {
      img {
        top: 0px;
      }
    }
  }
}

.mobile {
  display: block;

  @media(min-width: $mobile) {
    display: none;
  }
}

.page-content.examples {

  #hero {
    @media(min-width: $mobile) {
      // height: 800px;
    }
    .content {
      height: 400px;

      @media(min-width: $mobile) {
        // height: 400px;

        div {
          width: 100%;
        }
      }
    }
  }

  .below {
    color: darken($black, 0);
    font-size: 21px;
    @media(min-width: $mobile) {
      font-size: 26px;
    }
    p {
      // opacity: .75;
      margin-bottom: 0px;
    }
    font-weight: 600;
    text-align: center;
  }

  // h6, .below p {
    // font-family: $serif;
  // }

  // h6, .below {
  //   font-size: 17px;
  //   @media(min-width: $mobile) {
  //     font-size: 28px;
  //   }
  //   margin-top: 10px;
  //   color: darken($off-white, 60);
  //   text-align: center;
  // }

  .below {
    margin-bottom: 50px;

    @media(min-width: $mobile) {
      margin-top: 40px;
      margin-bottom: 0px;
    }

    a {
      @include hero-button;
      display: block;
      vertical-align: middle;
      margin: 0px auto;
      margin-top: 14px;
    }
  }

  #example-list {
    display: block;
    position: relative;
    z-index: 3;
    margin-top: -230px;

    @media(min-width: $mobile) {
      margin-bottom: 80px;
    }

    .wrapper {
      @include clearfix;
    }

    .column {
      background: $white;
      border-radius: $border-radius;
      overflow: hidden;
      box-shadow: 0 0 0 1px rgba(0,0,0,.10);
      margin-bottom: 25px;

      @media(min-width: $mobile) {
        float: left;
        width: 32.333%;
        margin-bottom: 0px;
        margin: 0 .5%;
  
        &:first-child {
          margin-left: 0px;
          margin-right: 1%;
        }

        &:last-child {
          margin-right: 0px;
          margin-left: 1%;
        }
      }

      > div {
        text-align: center;
        background: darken($purple, 0);
        color: $white;
        padding: 20px 20px;

        h3 {
          font-weight: 600;
          font-size: 24px;
        }

        p {
          font-size: 17px;
          color: $white;
          opacity: .9;
          margin-bottom: 0px;
          margin-top: -3px;
          font-weight: 600;
        }
      }

      &.software > div {
        background: $slate;
      }

      &.media > div {
        background: $dark-blue;
      }

      ul {
        li {
          display: block;
          padding: 20px 20px;
          cursor: pointer;
          transition: color $slow;

          &:hover {
            color: $blue;
            transition: color $fast;
          }

          &:nth-child(odd) {
            // background: darken($off-white, 5);
            background: #f6f9fc;
          }

          h6 {
            font-weight: 600;
            font-size: 18px;
          }

          p {
            opacity: .60;
            margin-top: 2px;
            margin-bottom: 0px;
            font-size: 16px;
            color: $black;
          }
        }
      }
    }
  }
}

.page-content.blog,
.page-content.case-studies,
.page-content.resources {
  .posts {
    @include wrapper;
    @include clearfix;
    z-index: 3;
    position: relative;
    margin-top: -200px;

    > div {
      @media(min-width: $mobile) {
        margin-left: -10px;
        width: calc(100% + 20px);
      }
    }

    .post-thumbnail {
      @include post-thumbnail
    }
  }
}

.page-content.resources {
  .posts {
    margin-top: 0px;
  }

  .options {
    text-align: center;

    @media(min-width: $mobile) {
      padding: 0px;
      margin-bottom: 30px;
    }

    > a {
      text-align: center;
      display: inline-block;
      padding: 50px 30px;
      vertical-align: top;
      border-radius: $border-radius;
      border: 1px solid darken(#fad133, 0);
      min-height: 300px;
      margin-bottom: 50px;

      @media(min-width: $mobile) {
        width: 400px;
        margin: 0 7px;
      }

      .icon {
        text-align: center;
      }

      i {
        font-size: 52px;
        margin: 0px auto;
        margin-bottom: 20px;
        color: darken($off-white, 30);
        display: inline-block;
        transition: color $fast;
      }

      .title {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      p {
        font-size: 18px;
        font-weight: 600;
      }

      &:hover {
        color: $black;

        .title {
          text-decoration: underline;
        }

        i {
          color: $black;
        }
      }
    }
  }

  .resource-section {
    padding: 50px 0 50px;
    position: relative;

    .posts {
      padding: 0px;
    }
    .post-thumbnail {
      .copy {
        background: #fde899;

        p {
          color: $black !important;
        }

        .wave-divider {
          svg path {
            fill: darken(#fde899, 0);;
          }
        }
      }

      &:hover .copy {
        background: $white;
      }
    }

    &:nth-child(odd) {
      background: lighten(#faf8f5, 0);

      .resource-title p {
        // color: darken(#fde899, 70) !important;
      }
    }

    // &:after {
    //   opacity: .25;
    //   position: absolute;
    //   content: '';
    //   top: 0px;
    //   left: 0px;
    //   width: 100%;
    //   height: 5px;
    //   background: linear-gradient(180deg, #fde899, #fad133 150%);
    // }

    &.first {
      margin-top: -20px;

      &:after {
        display: none;
      }
    }

    .resource-title {
      display: block;
      text-align: center;

      h4 {
        font-weight: 700;
        font-family: $sans;
        font-size: 28px;
        line-height: 1.25;
        @media(min-width: $mobile) {
          font-size: 48px;
        }
      }

      p {
        font-weight: 400;
        font-family: $serif;
        font-size: 24px;
        margin-bottom: 30px;
        color: darken($off-white, 50);

        @media(min-width: $mobile) {
          font-size: 32px;
        }
      }
    }

    .below {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: $black;

      a {
        position: relative;
        display: inline-block;

        span {
          position: relative;
          display: inline-block;
          line-height: 1.25;
          border-bottom: 2px dashed darken($off-white, 10);
        }

        i {
          margin-left: 10px;
          color: darken($off-white, 30);
        }
      }
    }
  }

  .wave-divider.inverted {
    svg path {
      // fill: #f6f9fc !important;
    }
  }
}

.page-content.case-studies {
  #hero {
    @media(min-width: $mobile) {
      height: 980px;

      .content > div {
        width: 100%;
      }
      .content {
        height: auto;

        h4 {
          margin-bottom: 45px;
        }
      }
    }
    #case-study-slider {
      padding-bottom: 90px;

      @media(max-width: $mobile) {
        display: none;
      }

      .left {
        text-align: left;
        .bottom p {
          color: $black;
        }
        .bottom:before {
          color: #c12f6a;
        }
      }

      .next,.prev {
        color: $white;
      }
      .navigation {
        > div > div {
          background: $white;
        }
        .progress-circle {
          .meter {
            stroke: rgba($white, .25);
          }
          .value {
            stroke: $white !important;
          }
        }
      }
    }
  }
  
  .posts {
    margin-top: 20px;
    @media(min-width: $mobile) {
      margin-top: -110px;
      // margin-top: -100px;
    }
  }
}

body.post {
  background: $off-white;

  #hero {
    background: $off-white;
  }

  .wave-divider {
    svg path {
      fill: $off-white !important;
    }
  }
}

#share {
  margin-right: -5px;

  > div {
    display: inline-block;
    line-height: 36px;
    font-size: 24px;
    margin-left: 5px;

    @media(min-width: $mobile) {
      margin-left: 12px;
      font-size: 26px;
      line-height: 40px;
    }
    vertical-align: middle;
    display: inline-block;
    position: relative;
    top: 3px;
    cursor: pointer;
    padding: 0 5px;
    transform: scale(1) translateZ(0px);
    transition: transform $slow, color $slow;
    color: $white;

    &:hover {
      color: $white;
      transform: scale(1.1) translateZ(0px);
      transition: transform $fast, color $fast;
    }

    &:first-child {
      margin-left: 0px;
    }

    &:before {
      @include fab;
      content: '\f099';
    }

    &.facebook:before {
      content: '\f39e';
    }

    &.linkedin:before {
      content: '\f0e1';
    }

    &.email:before {
      @include fas;
      content: '\f0e0';
    }
  }
}

.page-content.post {

  #post-content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1000px;
    padding: 0 2px;
    margin-top: -500px;

    @media(min-width: $mobile) {
      padding: 0 15px;
      margin: 0px auto;
      margin-top: -600px;
    }

    .loom-embed {
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: 1px solid darken($off-white, 30);
        z-index: 2;
        pointer-events: none;
        border-radius: 10px;
      }
    }

    > .actions {
      display: block;
      margin-bottom: 50px;

      a {
        color: $white;
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;

        @media(min-width: $mobile) {
          font-size: 32px;
          line-height: 40px;
        }

        &.back {
          font-family: $title;
          vertical-align: middle;
          display: inline-block;

          &:before {
            @include fas;
            content: '\f053';
            margin-right: 10px;
            font-size: 24px;
            vertical-align: middle;
            position: relative;
            top: -2px;

            @media(min-width: $mobile) {
              font-size: 26px;
              line-height: 32px;
            }

          }
        }
      }

      .left, .right {
        float: left;
        height: 40px;
      }

      .right {
        float: right;
      }
    }
  }

  #post {
    min-height: 1000px;
    // margin-top: -500px;
    position: relative;
    z-index: 3;
    background: $white;
    border-radius: $border-radius;
    // box-shadow: 0 10px 20px -4px rgba(50,50,93,.25), 0 6px 12px -6px rgba(0,0,0,.3), 0 -4px 12px -3px rgba(0,0,0,.025);
    margin-bottom: 50px;
    border: 1px solid darken($off-white, 5);
    padding-bottom: 50px;
    // box-shadow: 0 30px 60px -12px rgba(50,50,93,.05), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
    margin-bottom: 100px;
    padding-top: 60px;

    > * {
      padding: 0 15px;

      @media(min-width: $mobile) {
        padding: 0px 150px;
      }
    }

    .video-wrapper {
      position: relative;
      width: 100%;
      @include loading-alt;
      padding-top: 76%;

      video {
        border-radius: $border-radius;
        overflow: hidden;
        width: 100%;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        z-index: 3;
        // height: 100%;
      }
    }

    .header-pill {
      border-radius: 500px;
      background: $blue;
      color: $white;
      display: inline-block;
      padding: 5px 20px;
      font-weight: 400;
      margin-left: -10px;
      margin-bottom: 20px;

      @media(min-width: $mobile) {
        margin-bottom: 0px;
        margin-left: -20px;
      }

      strong {
        font-weight: 700;
      }
    }

    .case-study-subhead {
      margin-bottom: 40px;
      font-size: 19px;

      @media(min-width: $mobile) {
        margin-top: -20px;
      }

      > div {
        font-weight: 700;

        > span {
          color: $blue;
        }
      }
    }  

    hr {
      box-shadow: none;
      border: none;
      border-top: 2px dashed darken($off-white, 5);
      display: block;
      margin-bottom: 50px;
      margin-top: 50px;
      height: 0px;
      background: $white;
      border-bottom: 0px;
    }  

    h1 {
      font-size: 28px;
      margin-bottom: 20px;
      font-weight: 700;
      margin-top: 0px;
      line-height: 1.1;

      @media(min-width: $mobile) {
        margin-top: 20px;
        font-size: 40px;;
        // text-align: center;
        margin-bottom: 40px;
        line-height: 1.20;
      }

      .sub {
        font-size: 16px;
        margin-top: 10px;

        @media(min-width: $mobile) {
          font-size: 21px;
          margin-top: 0px;
        }
        font-weight: 700;
        color: $grey;
      }
    }

    h2 {
      font-size: 24px;
      margin: 30px 0;
      line-height: 1.25;

      @media(min-width: $mobile) {
        font-size: 34px;
      }
      font-weight: 700;
    }

    h3 {
      font-size: 21px;
      @media(min-width: $mobile) {
        font-size: 26px;
      }
      margin: 30px 0;
      font-weight: 600;
    }

    .center {
      text-align: center;
    }

    button {
      @include hero-button-blue;
      max-width: none;
      width: auto;
      padding: 0 20px;
      margin-bottom: 40px;
    }

    p, li {
      font-size: 17px;
      line-height: 1.7;

      @media(min-width: $mobile) {
        font-size: 19px;
        line-height: 1.80;
      }
      margin-bottom: 30px;
    }

    blockquote {
      color: darken($blue, 35);
      background: rgba($blue, .05);
      border-left: 3px solid lighten($blue, 0);
      line-height: 1.3;
      margin: 40px 0px;
      font-size: 19px;
      padding: 30px 0px;
      padding-left: 20px;
      font-weight: 500;

      strong {
        font-weight: 500;
      }

      @media(min-width: $mobile) {
        margin: 40px 0px;
        font-size: 24px;
        padding: 50px 150px;
      }
    }

    .note {
      color: darken($grey, 30);
      font-style: italic;
      background: lighten($off-white, 2);
      border-top: 1px solid darken($off-white, 2);
      border-bottom: 1px solid darken($off-white, 2);
      font-weight: 5600;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    ul.reset li {
      margin-bottom: 10px;
    }

    ul.reset {
      margin-bottom: 30px;
    }

    ul:not(.reset) {
      margin: 30px 0;
      display: block;

      > li {
        margin-bottom: 10px;
        color: $black;
        padding-left: 30px;
        position: relative;

        &:before {
          @include fas;
          position: absolute;
          top: 11px;
          left: 0px;
          font-size: 10px;
          transform: scale(.85);
          content: '\f111';
          color: darken($off-white, 10);
        }
      }
    }

    ol {
      > li {
        list-style: none;
        margin-bottom: 30px;
        counter-increment: ordered-list;

        &:before {
          content: counter(ordered-list) ". ";
        }

        p {
          display: inline;
        }
      }
    }

    ol, ul {
      ol, ul, p {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
    }

    .img {
      text-align: center;
      margin: 30px 0;

      img {
        width: 100%;
        display: inline-block;
        max-width: 400px;
      }

      &.wide img {
        width: 100%;
        max-width: none;
      }

      &.extra-wide img {
        width: 100%;
        max-width: none;

        @media(min-width: $mobile) {
          width: 120%;
          margin-left: -10%;
        }
      }

      &.border-radius {
        img {
          border-radius: $border-radius;
          overflow: hidden;
        }
      }

      &.shadow img {
        // box-shadow: 0 1px 2px 0px rgba(0,0,0,.1);
        border: 1px solid darken($off-white, 10);
      }

      &.large img {
        max-width: 500px;
      }

      .caption {
        color: $grey;
        font-style: italic;
        font-size: 14px;
        margin-top: 5px;
      }
    }

    .block-quote {
      padding: 0px;
      border: 1px solid darken($off-white, 3);
      background: lighten($off-white, 2);
      color: $blue;
      padding: 25px 0;
      width: calc(100% + 2px);
      margin-left: -1px;
      @media(min-width: $mobile) {
        padding: 50px 0;
      }
      font-weight: 300;
      margin-bottom: 30px;

      p {
        font-size: 21px;
        padding: 0 15px;
        max-width: 625px;
        margin: 0px auto;
        text-align: left;
        line-height: 1.7;

        @media(min-width: $mobile) {
          padding: 0px;
          font-size: 28px;
        }

        em {
          color: darken($grey, 10);
          font-style: normal;
        }
      }
    }

    .full-width.img {
      padding: 0px;

      img {
        width: 100%;
        max-width: none;
        border-top: 1px solid darken($off-white, 3);
        border-bottom: 1px solid darken($off-white, 3);
        line-height: 0px;
      }

      &.no-border {
        img {
          border: none;
        }
      }

      &.has-gradient {
        margin-top: -15%;
        margin-bottom: -15%;

        &.tight {
          margin-top: -10%;
          margin-bottom: -10%;          
        }
      }
    }

    strong {
      font-weight: 700;

      &.blue {
        color: darken($blue, 20);
      }
    }

    .quotation.block,
    .warning.block {
      padding-top: 20px;
      padding-bottom: 1px;
      background: lighten($blue, 45);
      color: darken($blue, 35);
      border-top: 1px solid darken($off-white, 3);
      border-bottom: 1px solid darken($off-white, 3);
      margin-bottom: 20px;
    }

    .quotation.block.bold {
      border-left: 3px solid lighten($blue, 0);
      position: relative;

      &:before {
        @include fas;
        color: lighten($blue, 42.5);
        content: '\f10d';
        position: absolute;
        z-index: 0;
        font-size: 65px;
        top: 0px;
        left: 80px;
        opacity: 1;
        // -webkit-text-stroke: 1px darken($blue, 0);
        // -webkit-text-fill-color: lighten($blue, 40);

        @media(max-width: $mobile) {
          display: none;
        }
      }

      p, li {
        font-size: 19px;
        margin-bottom: 20px;
        line-height: 1.5;
        font-weight: 600;
        color: darken($blue, 35);
      }

      li {
        margin-bottom: 10px;
        &:before {
          color: lighten($blue, 35);
        }
      }

      ul {
        margin-top: 0px;
      }
    }

    .warning.block {
      background: lighten($yellow, 45);
      color: darken($yellow, 45);
    }

    em {
      font-style: italic;
    }

    a {
      font-weight: 600;
      color: darken($blue, 20);
      text-decoration: underline;

      &:hover {
        color: darken($blue, 30);
        text-decoration: underline;
      }
    }

    .author {
      padding: 0px;
      width: 80px;
      height: 80px;
      transform: translateY(-50%);
      border-radius: 500px;
      overflow: hidden;
      border: 1px solid darken($off-white, 3);
      margin: 0px auto;

      img {

      }
    }

    .list {
      padding: 0px;

      .item {
        padding-bottom: 25px;
        padding-top: 25px;
        border-bottom: 1px solid darken($off-white, 3);

        &:first-child {
          border-top: 1px solid darken($off-white, 3);
        }

        > * {
          padding: 0 10px;

          @media(min-width: $mobile) {
            padding: 0px 150px;
          }
        }

        .meta {
          @include clearfix;
          margin-bottom: 20px;
          position: relative;

          .logo {
            height: 100px;
            border-radius: $border-radius;
            float: left;
            margin-right: 20px;
            box-shadow: 0 1px 2px 0px rgba(0,0,0,.1);
          }

          .center {
            position: absolute;
            left: calc(100px + 20px);
            transform: translateY(-50%);
            top: 50%;
            text-align: left;
          }

          .title {
            color: $black;
            font-size: 21px;
            font-weight: 500;

            h2 {
              margin: 0px;
              margin-bottom: 0px;
            }
          }

          .subtitle {
            display: block;
            font-size: 21px;
            font-weight: 500;
            color: $grey;
          }
        }

        .image {
          border-radius: $border-radius;
          overflow: hidden;
          box-shadow: 0 1px 2px 0px rgba(0,0,0,.1);
        }
      }
    }

    pre {
      margin-bottom: 30px;
    }
  }

  #get-started-alt {
    background: linear-gradient(150deg,darken($blue, 15) 15%, darken($teal, 5) 70%, lighten($teal, 10) 94%);
    padding-top: 100px;
    margin: 0px;

    &:before {
      display: none;
    }

    h3 {
      color: $white;
    }

    h6 {
      color: lighten($teal, 30);
    }
  }
}

.page-container.faq,
.page-container.pricing {
  #faq {
    display: block;
    position: relative;
    z-index: 3;
    margin-top: -200px;

    @media(min-width: $mobile) {
      margin-bottom: 80px;
    }

    .wrapper {
      @include clearfix;

      > div.questions {
        background: $white;
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: 0 0 0 1px darken($off-white, 5);
        margin-bottom: 25px;
        max-width: 1000px;

        li {
          display: block;
          padding: 30px 30px;

          &:nth-child(odd) {
            background: #f6f9fc;
          }

          h6 {
            font-weight: 600;
            font-size: 19px;
            margin-bottom: 10px;
          }

          p {
            opacity: .70;
            margin-top: 2px;
            margin-bottom: 0px;
            font-size: 16px;
            font-weight: 400;
            color: $black;
            line-height: 1.5;

            a {
              text-decoration: underline;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.page-container.reviews {

  .page-content.reviews {
    overflow: hidden;
  }

  #hero {
    background: transparent;

    #stripes {
      background: transparent;
    }
  }

  #primary {
    background: linear-gradient(150deg, darken(#c9316f, 2) 15%, darken(#c9316f, 10) 70%);

    @media(min-width: $mobile) {
      // background: linear-gradient(45.34deg, darken(#971313, 10) 5.66%, darken(#971313, 10) 94.35%);
    }

    > svg {
      position: absolute;
      width: 1000px;
      height: 1000px;
      buffered-rendering: static;
      transform: traslateX(-50%) translateY(-50%) translateZ(0px);
      top: calc(50% - 500px);
      right: -300px;
      fill: rgba(darken(#c9316f, 10), .05);
      // display: none;

      @media(max-width: $mobile) {
        display: none;
      }
    }
  }

  #get-started-alt {
    overflow: visible;
  }

  #customer-reviews {
    display: block;
    position: relative;
    z-index: 3;
    margin-top: -270px;

    @media(min-width: $mobile) {
      margin-bottom: 80px;
    }


    .wrapper {
      @include clearfix;
      margin-bottom: 50px;

      > div.review-list {
        max-width: 1000px;

        > ul {
          @include clearfix;
        }

        > ul > li {
          max-width: 710px;
          width: 100%;
          background: $white;
          border-radius: $border-radius;
          overflow: hidden;
          margin-bottom: 20px;
          position: relative;
          // box-shadow: 0 30px 60px -12px rgba(50,50,93,.15), 0 18px 36px -18px rgba(0,0,0,.2), 0 -12px 36px -8px rgba(0,0,0,.015);
          border: 2px solid lighten(#c9316f, 10);
          display: block;
          padding: 30px 20px;

          @media(min-width: $mobile) {
            float: left;
            padding: 30px 60px;
            margin-bottom: 40px;
          }

          &:after {
            // position: absolute;
            // top: 0px;
            // left: 0px;
            // width: 100%;
            // height: 20px;
            // background: darken(#ee9ca7, 20);
            // content: '';
          }

          &:nth-child(even) {
            @media(min-width: $mobile) {
              float: right;
            }

            &:after {
              // @media(min-width: $mobile) {
              //   background: darken(#ee9ca7, 10);
              // }
            }
          }

          h6 {
            font-size: 21px;
            @media(min-width: $mobile) {
              font-size: 26px;
            }
            font-weight: 700;
            margin-bottom: 20px;
            // margin-top: 20px;
            line-height: 1.5;
            color: darken(#c9316f, 30);
          }

          p {
            line-height: 1.75;
            margin-bottom: 20px;
            opacity: .75;
            font-size: 16px;
            color: darken($black, 20);

            @media(min-width: $mobile) {
              font-size: 18px;
            }
          }

          .author {
            margin-bottom: 5px;

            p {
              margin: 0px;
              color: darken(#c9316f, 30);
              font-size: 16px;

              @media(min-width: $mobile) {
                font-size: 18px;
              }

              > span {
                font-weight: 600;
              }

              > a {
                color: $blue;
                font-weight: 700;

                &:hover {
                  color: darken($blue, 10);
                }
              }
            }
          }
        }
      }
    }
  }
}

.page-container.pricing {
  #hero {

  }

  #primary {
    &, #hero {
      background: $white;
    }
    .pricing-container {
      position: relative;
      z-index: 3;

      @include pricing-plans;
      margin-top: -250px;
      margin-bottom: 30px;

      @media(min-width: $mobile) {
        margin-bottom: 50px;
      }

      .wrapper .plans {
        margin-top: 0px;
      }
    }
  }

  .wave-divider svg path {
    // fill: #f6f6f6 !important;
  }

  #create-account {
    background: linear-gradient(150deg,darken(#cc2b5e, 15) 15%, lighten(#753a88, 10) 94%);

    #questions-feedback .actions a {
      @include hero-button-red;
    }
  }
}

.page-container.wordpress {
  #hero {
    .img {
      mix-blend-mode: multiply;
      // filter: sepia(100%);
    }

    .img img {
      box-shadow: none;
      width: 550px;
      // max-width: none;
      margin-top: -500px;
      margin-right: -40px;
      max-width: calc(100% - 500px);
    }

    .actions .start {
      background: $blue;
      width: 230px;
    }
  }
}

.page-container.affiliates {
  #hero {
    .wide {
      @include hero-button;
      width: 250px;
    }

    .img img {
      box-shadow: none;
      width: 540px;
    }
  }

  .affiliate-program {
    display: block;
    position: relative;
    z-index: 3;

    div.section {
      margin-bottom: 60px;
      position: relative;
      z-index: 1;

      &.intro {
        .wrapper {
          max-width: 850px;
          margin: 0px auto;
        }

        p {
          font-size: 18px;
          margin-top: 20px;

          @media(min-width: $mobile) {
            font-size: 21px;
            margin-top: 30px;
          }
          font-weight: 400;
          color: $black;
          opacity: .9;
          line-height: 1.75;

          &:first-of-type {
            margin-top: 0px;
          }

          a {
            text-decoration: underline;
            font-weight: 600;
          }
        }
      }

      h3 {
        font-size: 32px;
        text-align: center;
        margin-bottom: 40px;

        @media(min-width: $mobile) {
          font-size: 48px;
          margin-bottom: 60px;
        }

        font-weight: 400;
        color: #cc2b5e;
      }

      h4 {
        font-weight: 600;
        letter-spacing: .25px;
        font-size: 16px;
        text-align: center;

        @media(min-width: $mobile) {
          font-size: 19px;
        }
        margin-bottom: 10px;
        color: lighten($grey, 5);
      }
    }

    div.section.partner-testimonials {
      border-top: 2px solid $off-white;
      margin-top: 60px;
      @include clearfix;

      &:before {
        @include fas;
        content: '\f653';
        color: #cc2b5e;
        font-size: 60px;
        background: $off-white;
        width: 120px;
        height: 120px;
        line-height: 120px;
        vertical-align: middle;
        text-align: center;
        border-radius: 500px;
        margin-bottom: 60px;
        margin: 0px auto;
        display: block;
        margin-top: -60px;
        margin-bottom: 60px;
      }

      .testimonials {
        @include clearfix;
        text-align: center;

        > div {
          display: inline-block;
          margin-bottom: 20px;

          @media(min-width: $mobile) {
            max-width: 350px;
            width: 30%;
            margin: 0 10px;
            min-height: 340px;
          }

          background: $white;
          border-radius: $border-radius;
          padding: 40px 40px;
          vertical-align: top;
          border: 1px solid $off-white;
          text-align: left;
          border: 1px solid darken($off-white, 5);

          h6 {
            color: darken(#753a88, 10);
            font-size: 24px;
            @media(min-width: $mobile) {
              font-size: 28px;
            }
            margin-bottom: 15px;
            font-weight: 400;
          }

          p {
            opacity: .8;
            line-height: 1.5;
            font-size: 16px;
            @media(min-width: $mobile) {
              font-size: 18px;
            }
          }

          .author {
            color: $grey;
            margin-top: 20px;
          }
        }
      }
    }

    div.section.how-to-join {
      padding: 10px 0 80px;

      h6 {
        font-weight: 600;
        font-size: 21px;
        @media(min-width: $mobile) {
          font-size: 24px;
        }
      }

      .actions {
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
        margin-bottom: -30px;

        a {
          @include hero-button;
          width: 250px;      
          margin: 0px auto;
        }
      }

      .wrapper {
        max-width: 900px;
        background: lighten($white, 43);
        border-radius: $border-radius;

        @media(min-width: $mobile) {
          padding: 30px 10px;
          margin: 0 5px;
          // border: 1px solid $off-white;
          // box-shadow: 0 30px 60px -12px rgba(50,50,93,.15), 0 18px 36px -18px rgba(0,0,0,.2), 0 -12px 36px -8px rgba(0,0,0,.015);
          box-shadow: 0 0 0 1px darken($off-white, 5);
        }
        @include clearfix;

        @media(min-width: $mobile) {
          margin: 0px auto;
          padding: 70px 100px;
          margin-top: -30px;
        }

        .steps {
          > div {
            margin-top: 50px;

            &:first-child {
              margin-top: 0px;
            }

            h6 {
              margin-bottom: 20px;
            }

            p {
              font-size: 17px;
              @media(min-width: $mobile) {
                font-size: 19px;
              }
              line-height: 1.65;
              opacity: .85;

              a {
                color: #cc2b5e;
                font-weight: 600;

                &:hover {
                  color: darken(#cc2b5e, 10);
                }
              }
            }
          }
        }
      }
    }

    div.section.faq {
      border-top: 2px solid $off-white;
      @include clearfix;

      &:before {
        @include fas;
        content: '\f059';
        color: #cc2b5e;
        font-size: 60px;
        background: $off-white;
        width: 120px;
        height: 120px;
        line-height: 120px;
        vertical-align: middle;
        text-align: center;
        border-radius: 500px;
        margin-bottom: 60px;
        margin: 0px auto;
        display: block;
        margin-top: -60px;
        margin-bottom: 60px;
      }

      h6 {
        font-weight: 600;
        font-size: 21px;
        margin-bottom: 20px;
        @media(min-width: $mobile) {
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      .questions {
        > div {
          margin-bottom: 50px;
        }
      }
    }
  }

  .section-group {
    position: relative;
    overflow: hidden;

    .blobs {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: none;

      @media(min-width: $mobile) {
        display: block;
      }

      svg {
        position: absolute;
        opacity: .05;

        &:nth-child(1) {
          top: 400px;
          right: 0px;
          max-width: 700px;
          height: auto;
          transform: translateY(-60%) translateX(30%) rotate(-29deg);
        }

        &:nth-child(2) {
          top: 850px;
          left: 0px;
          max-width: 900px;
          height: auto;
          transform: translateY(-50%) translateX(-40%) rotate(59deg);
        }

        &:nth-child(3) {
          bottom: 0px;
          right: 0px;
          max-width: 1200px;
          transform: translateY(50%) translateX(50%) rotate(19deg);
        }
      }
    }
  }

  #create-account {
    background: linear-gradient(150deg,darken(#753a88, 20) 15%, darken(#753a88, 12) 94%);

    .wide {
      width: 250px;
    }

    .red {
      @include hero-button-red;
    }
  }
}

.pagination {
  font-size: 18px;
  padding: 10px 0;

  @media(min-width: $mobile) {
    padding: 50px 0;
  }

  position: relative;
  z-index: 3;
  text-align: center;

  > a.page {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    line-height: 30px;
    background: darken($off-white, 5);
    border-radius: 3px;
    text-align: center;
    color: $black;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      color: $black;
      background: darken($off-white, 10);
    }

    &.active {
      background: $blue;
      color: $white;
    }
  }

  .prev, .next {
    margin: 0 15px;
    font-weight: 700;
  }

  .prev, .next {
    &.disabled {
      opacity: .5;
    }
  }

  .prev:before {
    @include fas;
    display: inline-block;
    margin-right: 7px;
    content: '\f053';
    font-size: 14px;
    line-height: 18px;
  }

  .next:after {
    @include fas;
    display: inline-block;
    content: '\f054';
    margin-left: 7px;
    font-size: 14px;
    line-height: 18px;
  }
}

.wave-divider {
  display: block;
  width: 100%;
  padding-top: 6%;
  height: 0px;
  width: 100%;
  overflow: hidden;
  transform: translateZ(0px);

  &.inverted {
    transform: matrix(1,0,0,-1.1,0,0) translateZ(0px);
  }

  &.static svg path {
    animation: none !important;
  }

  svg {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
  }

  svg path {
    animation: waveDividerAnimation 20s linear infinite alternate;
  }
}

@keyframes waveDividerAnimation{
  0%{
    d:path("M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z")}
    50%{d:path("M655.558582,28.4898877 C500.191373,61.6062216 422.833785,83.5422763 266.940085,49.8995879 C112.480383,16.5663637 84.0992497,8.02840272 0,44.7243294 L0,150.727527 L1920,150.727527 L1920,49.8995879 C1580.91676,-34.8808285 1438.10804,73.6768193 1148.0266,38.0019829 C857.945166,2.32714659 810.925791,-4.62644617 655.558582,28.4898877 Z")}
    to{d:path("M842.322034,38.0019829 C686.954825,71.1183168 623.386282,53.08497 467.492582,19.4422816 C313.032879,-13.8909426 84.0992497,8.02840272 0,44.7243294 L0,150.727527 L1920,150.727527 L1920,76.8685643 C1512.23604,-35.3595639 1568.70222,67.4289432 1278.62078,31.7541069 C988.539347,-3.92072949 997.689243,4.88564905 842.322034,38.0019829 Z")}}


#stars-banner {
  border-radius: 400px;
  border: 1px solid rgba($white, .5);
  margin: 0px auto;
  color: $white;
  font-size: 14px;
  max-width: 375px;
  margin-top: 0px;
  margin-bottom: 40px;

  @media(min-width: $mobile) {
    margin-top: -15px;
    margin-bottom: 60px;
    max-width: 520px;
    font-size: 18px;
  }

  > div {
    padding: 5px 0 6px;

    @media(min-width: $mobile) {
      padding: 10px 0 12px;
    }

    > span, .star-container, svg {
      display: inline-block;
      color: $white;
      vertical-align: middle;
    }

    > span {
      font-weight: 500;

      a {
        color: $white;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    .star-container {
      margin: 0 5px;

      @media(min-width: $mobile) {
        margin: 0 10px;
      }

      i {
        @include fas;
        color: $yellow;
        position: relative;
        font-size: 14px;
        line-height: 18px;

        @media(min-width: $mobile) {
          top: 2px;
          line-height: 20px;
          font-size: 23px;
        }
      }
    }

    svg {
      display: inline-block;
      max-width: 70px;
      margin-left: 5px;

      @media(min-width: $mobile) {
        max-width: 103px;
        margin-left: 7px;
      }
    }
  }

  &.black {
    border-color: $black;
    color: $black;
    text-align: center;
    max-width: 420px;

    .star-container {
      margin-right: 0px;
    }

    > div {
      span, .star-container, svg {
        color: $black;
      }

      > span a {
        color: $black;
      }

      svg {
        display: none;
      }
    }
  }
}
.page-container#bfcm {
  #hero h3 {
    font-size: 32px;

    @media(min-width: $mobile) {
      font-size: 44px;
    }

    > span {
      text-decoration: underline;
    }
  }

  #hero .content.center p {
    @media(max-width: $mobile) {
      font-size: 23px !important;
    }
  }

  #ecommerce-examples {
    padding-top: 50px;
    padding-bottom: 50px;

    @media(min-width: $mobile) {
      padding-top: 100px;
      padding-bottom: 80px;
    }

    .columns {
      margin-bottom: 50px;
    }
  }
}

#ecommerce-examples {
  .header {
    font-size: 28px;
    @media(min-width: $mobile) {        
      font-size: 32px;
    }
    margin: 0px auto;
    max-width: 800px;
    margin-bottom: 30px;
    text-align: center;
    font-family: $serif;
    @include serif-highlights;

    i {
      font-size: 28px;
      margin-left: 12px;
      color: darken($off-white, 30);
      position: relative;
      top: 16px;
    }
  }
  .columns {
    max-width: 900px;
    margin: 0px auto;
    text-align: center;
    @include clearfix;
    > .column {
      padding: 0 10px;
      margin-bottom: 10px;

      @media(min-width: $mobile) {        
        float: left;
        width: 50%;
        margin-bottom: 0px;
      }

      .content {
        text-align: left;
        display: inline-block;
        border-radius: $border-radius;
        vertical-align: top;
        box-shadow: 0 0 0 1px rgba(0,0,0,.10);
        overflow: hidden;
        font-size: 18px;
        background: $white;
        max-width: 440px;
      }

      .title {
        background-color: #2b4162;
        background-image: linear-gradient(225deg, #2b4162 0%, darken($slate, 20) 74%);
        color: $white;
        padding: 30px 30px 25px;
        line-height: 1.25;
        font-size: 25px;
        font-weight: 400;
        color: rgba($white, .85);

        strong {
          // text-decoration: underline;
          font-weight: 600;
          color: $white;
        }

        &.alt {
          background-color: darken($purple, 20);
          background-image: linear-gradient(225deg, darken($purple, 20) 0%, darken($slate, 20) 74%);
        }
      }

      .item {
        background: $white;
        color: $black;
        padding: 20px 30px;
        cursor: pointer;

        &:hover {
          h6 {
            color: $blue;
          }
        }

        h6 {
          font-weight: 700;
          font-size: 21px;
          margin-bottom: 5px;
          transition: color $fast;
        }

        p {
          font-size: 17px;
          opacity: .9;
          margin-bottom: 0px;
          line-height: 1.25;
          font-weight: 500;
          font-style: italic;
        }

        &:nth-child(odd) {
          // background: darken($off-white, 5);
          background: #f6f9fc;
        }
      }
    }
  }
  .below {
    margin: 0px auto;
    max-width: 800px;
    text-align: center;
    color: darken($black, 0);
    font-size: 21px;
    @media(min-width: $mobile) {
      font-size: 26px;
    }
    p {
      // opacity: .75;
      margin-bottom: 0px;
    }
    font-weight: 600;
    text-align: center;


    > div {
      margin-top: 20px;

      @media(min-width: $mobile) {
        height: 50px;
      }

      a {
        @media(max-width: $mobile) {
          display: block;
          margin: 0px auto;
          margin-bottom: 5px;
        }

        @include hero-button;
        margin: 0px;
      }

      > span {
        color: darken($off-white, 30);
        display: inline-block;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        vertical-align: top;
        text-align: center;
        width: 50px;

        @media(max-width: $mobile) {
          display: none;
        }
      }

      .learn-more {
        @include hero-button-blue;
        margin: 0px;
        @media(max-width: $mobile) {
          margin-top: 10px;
        }
      }

      .wide {
        width: 240px;
      }
    }
  }
}

#bfcm-case-studies,
#feature-highlights,
#review-marquee {
  padding: 50px 0;

  @media(min-width: $mobile) {        
    padding: 100px 0;
  }
  max-width: 1200px;
  margin: 0px auto;

  h6 {
    font-size: 26px;
    @media(min-width: $mobile) {        
      font-size: 32px;
    }
    text-align: center;
    font-family: $serif;
    @include serif-highlights;
  }

  #case-study-slider {
    margin-top: 40px;
    @media(min-width: $mobile) {        
      margin-top: 80px;
    }
  }
}

#index #review-marquee {
  h6 {
    display: none;
  }

  padding-bottom: 0px;
  max-width: 1150px;

  .marquee .inner {
    &:before, &:after {
      width: 200px;
      background: linear-gradient(90deg, lighten($off-white, 1), transparent);
    }

    &:after {
      background: linear-gradient(-90deg, lighten($off-white, 1), transparent);
    }
  }
}
#index #stars-banner {
  margin-top: 0px;
  margin-bottom: 10px;
  border: 1px solid darken($off-white, 10);
}

#ecommerce-examples {
  .wave-divider.inverted {
    top: auto;
    bottom: 0px;
    transform: translateY(98%) matrix(1,0,0,-1.1,0,0) translateZ(0);
  }
}

#bfcm-case-studies {
  padding: 80px 0;

  @media(min-width: $mobile) {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  h3 {
    text-align: center;
    font-family: $title;
    font-weight: 700;
    color: $black;
    font-size: 42px;
    padding: 0 10px;

    @media(min-width: $mobile) {
      padding: 0px;
      font-size: 52px;
    }
  }

  h6 {
    margin-top: 10px;
    color: $black;
  }
}

#case-study-slider {

  .next, .prev {
    width: 60px;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    opacity: .5;
    cursor: pointer;
    transition: opacity $fast;
    z-index: 3;

    &:hover {
      opacity: 1;
    }

    i {
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      width: 60px;
      font-size: 28px;
    }
  }
  .next {
    left: auto;
    right: 0px;
  }

  &:hover {
    .progress-circle {
      opacity: .25;
    }
  }

  .navigation {
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 10px;
    z-index: 3;
    display: none;
    @media(min-width: $mobile) {
      display: block;
    }

    > div {
      width: 10px;
      height: 10px;
      border-radius: 30px;
      display: inline-block;
      margin: 0 5px;
      vertical-align: top;
      opacity: .5;
      cursor: pointer;
      position: relative;
      transition: transform $fast;

      > div {
        width: 9px;
        height: 9px;
        border-radius: 30px;
        background: $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &.active {
        opacity: 1;
        transform: scale(1.25);

        .progress-circle {
          opacity: 1;
          transition: stroke-dashoffset .25s, opacity .5s .5s;
        }
      }

      .progress-circle {
        width: 10px;
        height: 10px;
        background: transparent;
        position: absolute;
        opacity: 0;
        transition: stroke-dashoffset 0s, opacity 0s 0s;
      }

      .progress {
        position: absolute;
        top: 0px;
        left: 0px;
        transform-origin: center;
        transform: rotate(-90deg) translateZ(0px) translateY(0) translateX(0) scale(1.8);
      }

      .meter,
      .value {
        fill: none;
        stroke-width: 2
      }

      .meter {
        // stroke: rgba(255, 255, 255, .25);
        stroke: rgba(#000000,.25);
      }

      .value {
        // stroke: rgba($white, 1);
        opacity: .5;
        stroke: rgba(#000000, 1);
        stroke-linecap: round;
        stroke-width: 2;
        transition: stroke-dashoffset .5s;
      }
    }
  }
  .container {
    position: relative;

    .slide {
      @include clearfix;
      opacity: 0;
      z-index: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: transform $slow, opacity $slow;

      padding: 0 10px;
      @media(min-width: $mobile) {
        padding: 0px;
      }

      .left {
        transition: transform $slow, opacity $fast;
        transform: translateY(-15px) rotate(0deg);
      }
      .right {
        transition: transform $slow, opacity $fast;
        transform: translateY(15px) rotate(0deg);
      }

      &.active {
        position: relative;
        z-index: 1;
        opacity: 1;

        .left, .right {
          transform: translateY(0px) rotate(0deg);
        }
      }

      .left, .right {
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: 0 0 0 1px rgba($black, .2);
      }

      .left {
        background: $off-white;
        position: relative;
        padding: 25px 50px;
        z-index: 1;
        @media(min-width: $mobile) {
          float: left;
          width: 40%;
          top: -25px;
          right: -60px;
        }

        .top {
          img {
            background: blue;
            width: 40px;
            height: 40px;
            display: block;
            border-radius: 50px;
            margin-bottom: 20px;
          }
          .title {
            font-size: 24px;
            margin-bottom: 0px;
            line-height: 1.25;
            font-weight: 700;
          }
          .role {
            font-size: 18px;
            opacity: .75;
            font-weight: 600;
            margin-bottom: 20px;
          }
        }
        .bottom {
          position: relative;

          &:before {
            @include fas;
            color: lighten($yellow, 30);
            content: '\f10d';
            position: absolute;
            font-size: 35px;
            top: 5px;
            left: -23px;
            transform: translateY(-50%) translateX(-50%);
            opacity: .5;
            z-index: 0;

            @media(max-width: $mobile) {
              display: none;
            }
          }

          p {
            font-size: 19px;
            line-height: 1.35;
            margin-bottom: 25px;
            font-weight: 500;
            position: relative;
            z-index: 1;

          }
          a {
            font-weight: 700;
            font-size: 18px;

            i {
              @include fas;
              color: $black;
              margin-left: 4px;
              opacity: .4;
              position: relative;
              top: 2px;
              transition: opacity $fast;
            }

            &:hover {
              color: $black;
              text-decoration: underline;

              i {
                opacity: 1;
                transition: opacity $fast;
              }
            }
          }
        }
      }
      .right {
        float: right;
        width: 60%;
        height: 100%;
        left: -60px;
        top: 25px;
        position: relative;
        display: none;
        @media(min-width: $mobile) {
          display: block;
        }

        img {
          display: block;
          line-height: 1;
        }
      }
    }
  }
}

#feature-highlights,
#review-marquee {
  max-width: 1100px;

  h6 {
    margin-bottom: 25px;

    @media(min-width: $mobile) {
      margin-bottom: 50px;
    }
  }
}

#feature-highlights {
  padding-bottom: 50px;
  text-align: center;

  .highlight {
    text-align: left;
    position: relative;
    border: 1px solid darken($off-white, 10);
    line-height: 1;
    border-radius: $border-radius;
    @include clearfix;
    display: block;
    margin: 5px 10px;

    @media(min-width: $mobile) {        
      margin: 2px 2px;
      display: inline-block;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      text-align: center;
      color: darken($off-white, 30);
      width: 60px;
      font-size: 31px;
    }

    .copy {
      float: left;
      font-size: 21px;
      font-weight: 600;
      padding: 20px 30px;
      padding-left: 70px;
    }
  }
}

$reviewCount: 3;
#review-marquee {
  padding-top: 0px;

  .review {
    width: 600px;
    border-radius: $border-radius;
    border: 1px solid darken($off-white, 10);
    padding: 20px 25px;
    vertical-align: middle;
    display: inline-block;

    .top {
      margin-top: 0px;
      margin-bottom: 10px;

      @include clearfix;

      .stars {
        float: right;
        color: $yellow;

        i {
          margin-right: 2px;
        }
      }
      .left {
        float: left;
      }
      .title {
        font-weight: 700;
        margin-bottom: 0px;
        font-size: 14px;
        @media(min-width: $mobile) {
          font-size: 18px;
        }
      }
      .location {
        font-weight: 700;
        opacity: .75;
        margin-top: -3px;
        font-size: 12px;
        @media(min-width: $mobile) {
          font-size: 14px;
        }
      }
    }
    .bottom {
      p {
        white-space: break-spaces;
        max-height: 100px;
        overflow: hidden;
        font-size: 13px;
        height: 60px;
        overflow: hidden;

        @media(min-width: $mobile) {
          font-size: 16px;
          height: auto;
        }
      }
    }
  }

  .marquee {
    margin-bottom: 3px;

    @media(min-width: $mobile) {
      margin-bottom: 5px;
    }

    .container {
      width: #{600 * $reviewCount + 10}px;
    }
  }
}

.strikethrough {
  text-decoration: line-through;
}

#free-trial, #free-trial-form {
  // #hero.short {
  //   @media(max-width: $mobile) {
  //     height: 420px;
  //   }
  // }

  // #hero .content {
  //   margin-top: -70px;
  //   @media(min-width: $mobile) {
  //     margin-top: -40px !important;
  //   }

  //   h3 {
  //     font-size: 28px;
  //     max-width: 1100px;
  //     margin: 0px auto;

  //     @media(min-width: $mobile) {
  //       font-size: 38px !important;
  //       margin-bottom: 15px;
  //     }
  //   }
  // }

  h6 {
    font-weight: 400;
  }

  h4, #review-marquee h6 {
    color: $black;

    span {
      color: darken($blue, 0);
    }
  }

  #hero-auto-scale {
    position: relative;
    z-index: 1;
    text-align: center;
    background: linear-gradient(#fde899,#fad133);
    padding: 70px 15px 80px;
    text-align: left;

    @media(min-width: $mobile) {
      padding: 100px 0 150px;
      text-align: center;
    }

    @include hero-copy;

    h3, p, h4, h5 {
      color: $black;
      margin-bottom: 10px !important;

      @media(min-width: $mobile) {
        margin-bottom: 20px !important;
      }
      padding: 0px;
    }

    h3 {
      font-size: 23px;
      max-width: 1100px;
      margin: 0px auto;

      @media(max-width: $mobile) {
        br {
          display: none !important;
        }
      }
      @media(min-width: $mobile) {
        font-size: 38px !important;
        margin-bottom: 15px;
      }
    }

    h4, h5 {
      @media(max-width: $mobile) {
        font-size: 20px !important;
        line-height: 25px !important;
      }
    }

    h5 {
      color: $black;
      font-weight: 500;
      background: $white;
      // border: 1px solid darken(#fad133, 10);
      display: inline-block;
      padding: 8px 10px;
      margin-left: -10px;
        border-radius: 15px;
      // box-shadow: 0 2px 8px 1px rgba(0,0,0,.15);
      width: calc(100% + 20px);

      @media(min-width: $mobile) {
        border-radius: 1000px;
        padding: 5px 40px;
        width: auto;
        margin-left: 0px;
      }
    }

    h5 {
      font-size: 17px;
      @media(max-width: $mobile) {
        font-size: 14px !important;
        line-height: 18px !important;
      }

      @media(min-width: $mobile) {
        margin-top: -20px;
      }

      strong {
        font-weight: 700;

        @media(min-width: $mobile) {
          // text-decoration: underline;
        }
      }
    }

    h4 {
      @media(min-width: $mobile) {
        margin-bottom: 0px !important;
      }
    }

    .wave-divider {
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 3;

      svg path {
        fill: $white;
      }
    }
  }

  #review-marquee {
    h6 {
      margin-bottom: 30px;
    }
  }

  #create-account {
    padding-top: 50px;;
    @media(min-width: $mobile) {
      padding-top: 150px;
    }

    #questions-feedback {
      // background: none;
      // border: none;

      .actions {
        a {
          width: 230px;
        }
      }
    }
  }

  #review-marquee {
    padding-bottom: 50px;
  }

  #feature-highlights {
    padding-top: 0px;
  }

  #featured-brands {
    margin-bottom: 0px;
  }

  div > h4, div > h6, .wrapper > h3 {
    font-size: 24px !important;
    line-height: 1.2;
    font-family: $serif;
    font-weight: 400;

    @media(max-width: $mobile) {
      padding: 0 15px;
      text-align: left !important;
    }

    @media(min-width: $mobile) {
      font-size: 27px !important;
    }
  }

  .wrapper > h3 {
    @media(max-width: $mobile) {
      padding: 0 5px;
      margin-bottom: 15px !important;
    }
  }

  div > h4 {
    @media(max-width: $mobile) {
      margin-bottom: 10px;
    }
  }

  h4 > span, h6 > a, h6 > span {
    &:after {
      display: none;;
    }
  }

  #featured-brands {
    @media(max-width: $mobile) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  #feature-highlights {
    h6 {
      margin-bottom: 30px;
    }
  }

  #faq {
    padding-bottom: 50px;

    h4 {
      margin-bottom: 30px;
      text-align: center;
    }
 
    .wrapper {
      @include clearfix;
      > div.questions {
        margin: 0px auto;
        background: $white;
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: 0 0 0 1px darken($off-white, 5);
        margin-bottom: 25px;
        max-width: 1000px;

        li {
          display: block;
          padding: 30px 30px;

          &.hidden {
            h6 {
              margin-bottom: 0px;
              cursor: pointer;

              &:before {
                @include fas;
                margin-right: 10px;
                content: '\2b';
                color: darken($off-white, 30);
              }

              &:hover:before {
                color: black;
              }
            }
            p {
              display: none;
            }
          }

          &.active {
            h6 {
              margin-bottom: 10px;
            }
            h6:before {
              content: '\f068';
            }
            p {
              display: block;
            }
          }

          &:nth-child(odd) {
            background: #f6f9fc;
          }

          h6 {
            font-weight: 600;
            font-size: 19px;
            margin-bottom: 10px;
          }

          p {
            opacity: 1;
            margin-top: 2px;
            margin-bottom: 0px;
            font-size: 16px;
            font-weight: 400;
            color: darken($off-white, 60);
            line-height: 1.5;

            a {
              text-decoration: underline;
              font-weight: 600;
              color: $black;
            }
            strong {
              color: $black;
            }
          }
        }
      }
    }
  }

  .faq-alt {
    padding-bottom: 30px !important;
  }

  .StripeElement {
    @include input;
    line-height: 46px;
    min-height: 46px;
    height: 46px;

    @media(max-width: $mobile) {
      line-height: 40px;
      min-height: 40px;
      height: 40px;
      padding: 0 15px;
    }
    transition: all 0s;
    margin-bottom: 0px;
  }

  #key-features, #backend-features {
    padding-top: 0px;

    .bottom {
      h6 i {
        display: none !important;
      }
    }
  }

  #backend-features,
  #key-features {
    padding-top: 0px;

    @media(max-width: $mobile) {
      h6 {
        padding: 0px !important;
      }
    }

    .bottom h6 {
      font-family: $sans;
      font-weight: 500;
    }

    .wrapper > h4, .wrapper > .content > h6 {
      display: none;
    }
    .features {
      margin-top: 0px;
    }
    .feature {
      margin-bottom: -1px;
      border-radius: 0px;
    }
    .first .feature {
      border-top-right-radius: 28px;
      border-top-left-radius: 28px;
    }
    .last .feature {
      border-bottom-right-radius: 28px;
      border-bottom-left-radius: 28px;
    }
  }
  #key-features {
    padding-bottom: 0px;
  }
  #backend-features {
    padding-top: 0px !important;
  }

  h5 {
    font-weight: 500;
    @media(min-width: $mobile) {
      font-size: 18px;
      margin-top: 8px;
    }
  }

  .below-form-info {
    // text-align: center;
    margin: 20px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 0px; 

    a {
      text-decoration: underline;
    }
  }

  .form-block {
    @include clearfix;
    max-width: 850px;
    margin: 0px auto;
    margin-top: -70px;
    z-index: 1;
    position: relative;

    @media(min-width: $mobile) {
      margin-top: -120px;
    }

    .pre-button {
      font-size: 19px;
      line-height: 1.25;
      font-weight: 500;
      text-align: center;
      max-width: 300px;
      margin: 0px auto;
      margin-bottom: 20px;

      @media(min-width: $mobile) {
        font-size: 24px;
        margin-bottom: 0px;
      }
    }

    form {
      @media(min-width: $mobile) {
        float: left;
        width: 560px;
      }
    }

    .feature-highlights {
      @include wrapper;

      @media(min-width: $mobile) {
        margin: 0px;
        width: calc(100% - 560px);
        padding-left: 10px;
        float: right;
      }

      .highlight {
        background: $white;
        text-align: left;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 8px;
        border: 1px solid darken($off-white, 30);
        position: relative;
        line-height: 1.1;
        @include clearfix;

        .icon {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          text-align: center;
          // color: darken($off-white, 30);
          opacity: .7;
          width: 26px;
          font-size: 16px;
        }

        .copy {
          float: left;
          font-size: 15px;
          font-weight: 500;
          padding-left: 24px;
        }
      }
    }
  }

  form {
    margin: 0 10px;
    border: 1px solid darken($off-white, 30);
    border-radius: 15px;
    padding: 20px 15px;
    position: relative;
    z-index: 2;
    background: $white;
    margin-bottom: 10px;

    @media(min-width: $mobile) {
      max-width: 560px;
      margin: 0px auto;
      padding: 50px 50px 35px;
    }

    label {
      @include label;
      margin-bottom: 3px;

      @media(max-width: $mobile) {
        font-size: 10px;
        margin-bottom: 2px;
      }
    }

    .shopify-input {
      @include clearfix;
      position: relative;

      @media(min-width: $mobile) {
        margin-top: 11px;
        margin-bottom: 12px;
      }

      input {
        width: calc(100% - 160px);
        float: left;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0px;
      }

      > div {
        @include input;
        width: 160px;
        float: left;
        text-align: center;
        line-height: 46px;
        min-height: 46px;
        height: 46px;
        font-size: 17px;
        background: $off-white;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-bottom: 5px;
        color: darken($off-white, 50);

        @media(max-width: $mobile) {
          line-height: 40px;
          min-height: 40px;
          height: 40px;
          padding: 0 15px;
          font-size: 15px;
        }
      }
    }

    input {
      @include input;
      margin-bottom: 0px;
      line-height: 46px;
      min-height: 46px;
      height: 46px;
      font-size: 17px;

      @media(max-width: $mobile) {
        line-height: 40px;
        min-height: 40px;
        height: 40px;
        padding: 0 15px;
        font-size: 15px;
      }
    }

    .row {
      margin-bottom: 14px;
      @media(max-width: $mobile) {
        margin-bottom: 12px;
      }
      .sub {
        font-size: 12px;
        font-weight: 400;
        margin-top: 4px;
        line-height: 14px;
      }
    }


    @include submitButton;

    button[type="submit"] {
      font-size: 20px;
      width: 100%;

      @media(min-width: $mobile) {
        margin-top: 10px;
      }
 
      > div {
        font-family: $sans;
        text-transform: none;
        font-weight: 600;
        display: block;
        max-width: none;
        letter-spacing: 0px;
      }
    }
  }

  #case-study-slider {
    max-width: 1200px;
    padding-bottom: 90px;
    margin: 50px auto;
    margin-top: 10px;

    @media(max-width: $mobile) {
      display: none;
    }

    .left {
      background: $white;
    }

    //   text-align: left;
    //   .bottom p {
    //     color: $black;
    //   }
    //   .bottom:before {
    //     color: #c12f6a;
    //   }
    // }

    // .next,.prev {
    //   color: $white;
    // }
    // .navigation {
    //   > div > div {
    //     background: $white;
    //   }
    //   .progress-circle {
    //     .meter {
    //       stroke: rgba($white, .25);
    //     }
    //     .value {
    //       stroke: $white !important;
    //     }
    //   }
    // }
  }
}

#free-trial {
  #featured-brands {
    background: transparent;
  }

  .below-form-block {
    max-width: 500px;
    margin: 0px auto;
    margin-top: 10px;
    margin-bottom: -10px;
    padding: 10px;
    border-radius: 10px;

    img {
      display: inline-block;
      line-height: 0;
    }
    .badges {
      margin-bottom: 10px;
      text-align: center;;

      img {
        max-height: 50px;
        width: auto;
        margin-right: 10px;
        &:first-child {
          margin-right: 30px;
        }
      }
    }
    .cards {
      text-align: center;;

      img {
        max-height: 30px;
        width: auto;
        margin-right: 5px;;
      }
    }
  }

  form {

  }
}

#pricing-toggle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: -15px;

  .interval {
    display: none;
    vertical-align: middle;
    font-weight: 600;
    margin-right: 15px;
    opacity: .75;
    color: $white;
  }

  > div.switch {
    border-radius: 300px;
    background: darken($off-white, 4);
    display: inline-block;
    box-shadow: 0 0 0 1px darken($off-white, 15);
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    height: 36px;
    @include clearfix;

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      background: $white;
      border-radius: 300px;
      z-index: 0;
      transition: left $fast;
      width: 120px;
      @media(min-width: $mobile) {
        width: 150px;
      }
    }

    &.annual:after {
      left: 120px;
      @media(min-width: $mobile) {
        left: 150px;
      }
    }

    > div {
      float: left;
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      line-height: 36px;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      z-index: 1;
      position: relative;
      transition: color $fast;
      width: 120px;
      @media(min-width: $mobile) {
        width: 150px;
      }
      // &.active {
      //   background: $white;
      //   border-radius: 300px;
      // }
    }
  }
}

.page-container.pricing {
  #pricing-toggle {
    margin-bottom: 10px;
    margin-top: 0px;

    @media(min-width: $mobile) {
      text-align: left;
      margin-top: -50px;
    }

    .interval {
      @media(min-width: $mobile) {
        display: inline-block;
      }
    }

    > div.switch {
      background: rgba($black, .25);
      color: rgba($white, 1);
      box-shadow: 0 0 0 1px rgba($off-white, .75);

      > div.active {
        color: $black;
      }
    }
  }

  #faq {
    margin-top: 0px;

    h4 {
      margin-bottom: 30px;
      text-align: center;
    }
 
    .wrapper {
      @include clearfix;
      > div.questions {
        margin: 0px auto;
        background: $white;
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: 0 0 0 1px darken($off-white, 5);
        margin-bottom: 25px;
        max-width: 1000px;

        li {
          display: block;
          padding: 20px 20px;

          @media(min-width: $mobile) {
          padding: 30px 30px;
          }

          &.hidden {
            h6 {
              margin-bottom: 0px;
              cursor: pointer;

              &:before {
                @include fas;
                margin-right: 10px;
                content: '\2b';
                color: darken($off-white, 30);
              }

              &:hover:before {
                color: black;
              }
            }
            p {
              display: none;
            }
          }

          &.active {
            h6 {
              margin-bottom: 10px;
            }
            h6:before {
              content: '\f068';
            }
            p {
              display: block;
            }
          }

          &:nth-child(odd) {
            background: #f6f9fc;
          }

          h6 {
            font-weight: 600;
            font-size: 19px;
            margin-bottom: 10px;
          }

          p {
            opacity: 1;
            margin-top: 2px;
            margin-bottom: 0px;
            font-size: 16px;
            font-weight: 400;
            color: darken($off-white, 60);
            line-height: 1.5;

            a {
              text-decoration: underline;
              font-weight: 600;
              color: $black;
            }
            strong {
              color: $black;
            }
          }
        }
      }
    }
  }
}
